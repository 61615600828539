import { env, loginAuthHead, endpoint, service_return } from '../_helpers';
import axios from 'axios'

const AXIOS = axios.create({
    baseURL: import.meta.env.VITE_API_URL
});

const EKYCAXIOS = axios.create({
    baseURL: env().EKYCBASEURL
});

const QRAXIOS = axios.create({
    baseURL: env().qrURL
});

export const loginService = {
    // WEB Login Methods
    verifyClient, validatePassword, sendWebAuthOtp, verifyWebAuthOtp ,resetPassword, forgotPass, forgotPassVerify, unblockAcc, unblockAccVerify,
    // EKYC Methods
    sendMobileOtp, verifyMobileOtp, sendEmailOtp, verifyEmailOtp,createPassword,
    // TOTP
    registerTotp, getScannerData, enableTotp, verifyTotp,
    // SSO
    getVendorDetails, checkVendorAuthorize, vendorAuthorize,
    // QR
    getQRCode, getQrSession
};


function verifyClient(data) {
    let json = {
        method: env().HTTP_method.GET,
        module: env().module.login,
        path: endpoint.verifyClient,
        payload: data.params,
        mutationName: data.loaderMutation,
    }
    return service_return(json)
}

function validatePassword(payload) {
    return AXIOS.post(`am/access/pwd/validate`, payload).then();
}
 
function sendWebAuthOtp(payload) {
    const requestOptions = {
        headers: loginAuthHead(),
    };
    return AXIOS.post(`am/access/otp/send`, payload, requestOptions).then();
}

function verifyWebAuthOtp(payload) {
    return AXIOS.post(`am/access/otp/validate`, payload).then();
}

function resetPassword(payload) {
    const requestOptions = {
        headers: loginAuthHead(),
    };
    return AXIOS.post(`am/access/pwd/reset`, payload, requestOptions).then();
}

function forgotPass(payload) {
    return AXIOS.post(`am/access/pwd/forget`, payload).then();
}

function forgotPassVerify(payload) {
    return AXIOS.post(`am/access/pwd/forget/verify`, payload).then();
}

function unblockAcc(payload) {
    return AXIOS.post(`am/access/client/unblock`, payload).then();
}

function unblockAccVerify(payload) {
    return AXIOS.post(`am/access/client/unblock/verify`, payload).then();
}

// TOTP APIS
function registerTotp(payload) {
    const requestOptions = {
        headers: loginAuthHead(),
    };
    return AXIOS.post(`am/access/scanner/generate`, payload, requestOptions).then();
}

function getScannerData(payload) {
    const requestOptions = {
        headers: loginAuthHead(),
    };
    return AXIOS.post(`am/access/scanner/get`, payload, requestOptions).then();
}

function enableTotp(payload) {
    const requestOptions = {
        headers: loginAuthHead(),
    };
    return AXIOS.post(`am/access/topt/enable`, payload, requestOptions).then();
}

function verifyTotp(payload) {
    const requestOptions = {
        headers: loginAuthHead(),
    };
    return AXIOS.post(`am/access/topt/verify`, payload, requestOptions).then();
}



// ekyc APIS

function createPassword(payload) {
    return EKYCAXIOS.post(`ekyc-rest/user/creation`, payload).then();
}

function sendMobileOtp(payload) {
    return EKYCAXIOS.post(`ekyc-rest/user/sendSmsOtp`, payload).then();
}

function verifyMobileOtp(payload) {
    return EKYCAXIOS.post(`ekyc-rest/user/verifySmsOtp`, payload).then();
}

function sendEmailOtp(payload) {
    return EKYCAXIOS.post(`ekyc-rest/user/sendMailOtp`, payload).then();
}

function verifyEmailOtp(payload) {
    return EKYCAXIOS.post(`ekyc-rest/user/verifyEmailOtp`, payload).then();
}

// SSO APIS

function getVendorDetails(payload) {
    return AXIOS.post(`am/sso/vendor/deatils`, payload).then();
}

function checkVendorAuthorize(payload) {
    return AXIOS.post(`am/sso/vendor/authorize/check`, payload).then();
}

function vendorAuthorize(payload) {
    return AXIOS.post(`am/sso/vendor/authorize`, payload).then();
}

// QR code login
function getQRCode() {
    return QRAXIOS.get(`auth/access/generate`).then();
}

function getQrSession() {
    return QRAXIOS.get(`auth/access/getqrvalue`).then();
}



