import { commonService } from '../../../_services/common.service';
import errHandle from '../../../handleError/errorHandling'
import { useNotification } from "@kyvg/vue3-notification";
const { notify } = useNotification()

const state = {
    isIPODetails:false,
    fields:[
        { quantity: 1, price: 0, atCutOff: true, amount: 0, remark: '', activityType: 'new' }
    ],
    masterIPOList:[],
    announcedList:[],
    recentList:[],
    ipoDetails:null,
    ipoLoader:false,

    // 
    onGoingIpo: [],
    btnLoader: false,
    appliedIpo: [],
    isDetailsDialog: false
}

const actions = {
    // async getMasterIPOList({ state, commit, dispatch, rootGetters }, payload) {
    //     commit('setMasterIPOList', [])
    //     commit('setIPOLoader', true)
    //     try {
    //         let resp = await commonService.getIPOMaster()
    //         if ( resp.data.status == "Ok" && resp.data.result) {
    //             commit('setMasterIPOList', resp.data.result)
    //         } else {
    //         }
    //     } catch (error) {
    //         errHandle.statusCodeCheck(error)
    //     }
    //     finally {
    //         commit('setIPOLoader', false)
    //     }
    // },

    // announced List
    async getAnnouncedList({ state, commit, dispatch, rootGetters }, payload) {
        commit('setAnnouncedList', [])
        commit('setIPOLoader', true)
        try {
            let resp = await commonService.getAnnouncedList()
            
            if ( resp.data.status == "Ok" && resp.data.result) {
                commit('setAnnouncedList', resp.data.result)
            } else {
            }
        } catch (error) {
            errHandle.statusCodeCheck(error)
        }
        finally {
            commit('setIPOLoader', false)
        }
    },

    async getRecentList({ state, commit, dispatch, rootGetters }, payload) {
        commit('setRecentList', [])
        commit('setIPOLoader', true)
        try {
            let resp = await commonService.getRecentList()
            
            if ( resp.data.status == "Ok" && resp.data.result) {
                commit('setRecentList', resp.data.result)
            } else {
            }
        } catch (error) {
            errHandle.statusCodeCheck(error)
        }
        finally {
            commit('setIPOLoader', false)
        }
    },

    async ongoingIpo({commit, dispatch}) {
        commit('setIPOLoader', true)
        await commonService.ongoingIpo().then(async resp => {
            if (resp.data.status == "Ok" && resp.data.result) {
                await dispatch('getAppliedIpo')
                resp.data.result.forEach(item => {
                    const [day, month, year] = item.biddingStartDate?.split("-");
                    const parsedDate = new Date(year, month - 1, day)
                    const currentDate = new Date();
                    const isBeforeCurrentDate = parsedDate < currentDate
                    item.status = 'Apply'
                    if(!isBeforeCurrentDate){
                        item.status = 'Upcoming'
                    }
                    state.appliedIpo.forEach(element => {
                        if(element.symbol == item.symbol && element.statusMessage == 'success'){
                            item.status = 'Applied'
                        }
                    });
                })
                resp.data.result.sort((a, b) => {
                    const priority = {
                        Apply: 1,
                        Applied: 2,
                        Upcoming: 3
                    };
                    const priorityA = priority[a.status]
                    const priorityB = priority[b.status]

                    const compareDates = (dateA, dateB) => {
                        const [dayA, monthA, yearA] = dateA.split('-');
                        const [dayB, monthB, yearB] = dateB.split('-');
                        return yearA - yearB || monthA - monthB || dayA - dayB;
                    };
                    if (a.status === b.status) {
                        return compareDates(a.biddingStartDate, b.biddingStartDate); // Earliest date first
                    }
                    return priorityA - priorityB;
                });
                commit('setOnGoingIpo', resp.data.result)
            } else {
                commit('setOnGoingIpo', resp.data.result)
            }
        },(error) => {
            errHandle.statusCodeCheck(error)
        }).finally(() => {
            commit('setIPOLoader', false)
        })
    },

    async placeIpoOrder({ commit, dispatch }, payload) {
        commit('setBtnLoader', true)
        await commonService.placeIpoOrder(payload).then(resp => {
            if (resp.status == 200 && resp.data.result[0].status != 'failed') {
                notify({ group: 'auth', type: 'success', title: resp.data.result[0].status })
                dispatch("ongoingIpo")
            } else if(resp.data.result[0].status == 'failed') {
                notify({ group: 'auth', type: 'error', title: resp.data.result[0].reason })
            }
        }, (error) => {
            errHandle.statusCodeCheck(error)
        }).finally(() => {
            commit('setBtnLoader', false)
            commit("setIsIPODetails", false);
            commit("setIPODetails", "");
            commit("setDefaultQty", [
                { quantity: 1, price: 0, atCutOff: true, amount: 0, remark: '', activityType: 'new' }
            ]);
        })
    },

    async getAppliedIpo({commit}) {
        commit('setIPOLoader', true)
        await commonService.getAppliedIpo().then(resp => {
            if(resp.status == 200 && resp.data.result?.length) {
                commit("setAppliedIpo", resp.data.result)
            } else {
                commit("setAppliedIpo", [])
            }
        }, (error) => {
            errHandle.statusCodeCheck(error)
        }).finally(() => {
            commit('setIPOLoader', false)
        })
    },

    async cancelIpoOrder({ commit, dispatch }, payload) {
        commit('setBtnLoader', true)
        await commonService.cancelIpoOrder(payload).then(resp => {
            if (resp.status == 200 && resp.data.result[0].status != "failed") {
                notify({ group: 'auth', type: 'success', title: resp.data.result[0].status })
            } else {
                notify({ group: 'auth', type: 'error', title: resp.data.result[0].status })
            }
            dispatch("getAppliedIpo")
        }, (error) => {
            errHandle.statusCodeCheck(error)
        }).finally(() => {
            commit('setBtnLoader', false)
        })
    },
};

const mutations = {
    setIsIPODetails(state, payload){
        state.isIPODetails = payload
    },
    setIPOLoader(state, payload){
        state.ipoLoader = payload
    },
    setIPODetails(state, payload){
        state.ipoDetails = payload        
    },
    setDefaultQty(state, payload){
        state.defaultQty = payload
    },  
    setMasterIPOList(state, payload){
        state.masterIPOList = payload
    },
    setAnnouncedList(state, payload){
        state.announcedList = payload
    },
    setRecentList(state, payload){
        state.recentList = payload
    },

    // 
    setOnGoingIpo(state, payload) {
        let empDiscount = 0
        let indDiscount = 0
        payload.forEach(el => {
            el.subCategorySettings.forEach(el1 => {
                if (el1.caCode == 'EMPRET' && el1.subCatCode == "EMP") {
                    el.empDiscount = el1.discountPrice
                    el.empAllowCutOff = el1.allowCutOff
                    el.empMaxUpiLimit = el1.maxUpiLimit
                }
                if (el1.caCode == 'RETAIL' && el1.subCatCode == "IND") {
                    el.indDiscount = el1.discountPrice
                    el.indAllowCutOff = el1.allowCutOff
                    el.indMaxUpiLimit = el1.maxUpiLimit
                }
            })
        });
        state.onGoingIpo = payload
    },
    setBtnLoader(state, payload) {
        state.btnLoader = payload
    },
    setAppliedIpo(state, payload) {
        payload.forEach(el => {
            el.statusMessage = Array.isArray(el.bids) && el.bids.length == 0 ? 'cancelled' : el.statusMessage
        });
        payload.sort((a, b) => {
            const priority = {
                success: 1,
                cancelled: 2,
                failed: 3
            };
            const priorityA = priority[a.statusMessage];
            const priorityB = priority[b.statusMessage];
            if (a.statusMessage === 'cancelled' && b.statusMessage === 'cancelled') {
                return new Date(b.createdOn) - new Date(a.createdOn); // Latest date first
            }
            if (a.statusMessage === 'success' && b.statusMessage === 'success') {
                return new Date(b.createdOn) - new Date(a.createdOn); // Latest date first
            }
            if (a.statusMessage === 'failed' && b.statusMessage === 'failed') {
                return new Date(b.createdOn) - new Date(a.createdOn); // Latest date first
            }
            return priorityA - priorityB;
        });
        state.appliedIpo = payload
    },
    setIsDetailsDialog(state, payload) {
        state.isDetailsDialog = payload
    },
    setFieldsQty(state, payload){
        state.fields = payload
    }
};

const getters = {
    getIPODetails: state => state.ipoDetails,
    getIPOMasterList: state => state.masterIPOList,
    getIPOLoader: state => state.ipoLoader,
    getAnnouncedList: state => state.announcedList,

    // 
    getOnGoingIpo: state => state.onGoingIpo,
    getRecentList: state => state.recentList,
    getAnnouncedList: state => state.announcedList,
    getIsIPODetails: state => state.isIPODetails,
    getFields: state => state.fields,
    getBtnLoader: state => state.btnLoader,
    getAppliedIpo: state => state.appliedIpo,
    getIsDetailsDialog: state => state.isDetailsDialog
};

const ipo = {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}

export default ipo