import { authHeader, env } from '../_helpers';
import axios from 'axios'

const AXIOS = axios.create({
    baseURL: import.meta.env.VITE_API_URL
});

export const commonService = {
    getAllSymbols, getBrokeageData, getUserSession, getMwList, getSearchList, deleteScripList, addScripList, getUserHoldings, getUserPositions,
    getContractInfo, getSectorDetails, getMargin, getOrderBook, getFundsData,getComFundsData, placeOrder, cancelOrder, modifyOrder, getUserPref, updateUserPref, addUserPref, getTradeBook, getTopSectors, getAlerts, createAlert, cancelAlert, modifyAlert,
    sortMwList, getETFDetails, getBasketData, getBasketScrips, createBasket, renameBasket, deleteBasket, addScripInBasket, deleteScripInBasket, inValidateSession, createWsSession, productConvertion, getProfileData,getOrderHistory, getTickerTapeList,
    getPredefinedMw, getOptionChain, getUnderlying, getUnderlyingExpiry, getSecurityInfo, getBrokerageDetails, squreOffAll, getWsSession, getBasketMargin, executeBasket, resetBasketExeStatus, changePassword, getPinnedScrips, pinToHeader, getAuthorizeHoldings,
    submitFeedback, exitBoCoPosition, getExchangeStatus, getExchangeMsg, getPopContent, pledgeData, revokeData, getTopGainers, getTopLosers, payinApiCall, payoutApiCall, getUserBankDetails, callHdfcPayment, checkHdfcPayment, getEdis, boRedirection,getIndianIndices, getIPOMaster,
    getAnnouncedList, getRecentList, editBasketScrip,getAdvanceMw,getGTTOrders, getSIPOrders, createPayment_razorpay, verifyPayment_razorpay, yearHigh, yearLow, riders, draggers, topvolume, meanreversion,
    ongoingIpo, placeIpoOrder, cancelIpoOrder, getAppliedIpo, accFreezeStatus, sendFreezeOTP, freezeOTPVerify
};

function getUserSession(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`auth-rest/auth/login`, payload, requestOptions).then();
}

function getAllSymbols(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`https://v2api.aliceblueonline.com/restpy/get_all_underlying`, payload, requestOptions).then();
}

function getBrokeageData(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`https://ant-uat.aliceblueonline.com/broCal/BrokerageCalculationService/brokerage/calculateBrokerage`, payload, requestOptions).then();
}

function getMwList(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`mw-rest/marketWatch/getAllMwScrips`, payload, requestOptions).then();
}

function getSearchList(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`scrips-rest/scrip/search`, payload, requestOptions).then()
}

function deleteScripList(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`mw-rest/advance/deletescrip`, payload, requestOptions).then();

}

function addScripList(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`mw-rest/advance/addscrip`, payload, requestOptions).then();
}

function getUserHoldings() {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.get(`ho-rest/holdings`, requestOptions).then();
}

function getUserPositions() {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.get(`po-rest/positions`, requestOptions).then();

}

function getContractInfo(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`scrips-rest/scrip/contract/info`, payload, requestOptions).then();
}

function getMargin(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`od-rest/orders/getmargin`, payload, requestOptions).then();
}

function getSectorDetails() {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.get(`common/eqsector`, requestOptions).then();
}

function placeOrder(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`od-rest/orders/web/execute`, payload, requestOptions).then();
}

function getOrderBook() {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.get(`od-rest/info/orderbook`, requestOptions).then();
}

function getFundsData(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.get(`funds-rest/funds/limits`, requestOptions).then();
}

function getComFundsData(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.get(`funds-rest/funds/limits/comm`, requestOptions).then();
}

function cancelOrder(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`od-rest/orders/web/cancel`, payload, requestOptions).then();
}

function modifyOrder(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`od-rest/orders/web/modify`, payload, requestOptions).then();
}

function getUserPref() {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.get(`client-rest/preferences/web`, requestOptions).then();
}

function updateUserPref(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`client-rest/preferences/web/update`, payload, requestOptions).then();
}

function addUserPref(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`client-rest/preferences/add`, payload, requestOptions).then();
}

function getTradeBook() {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.get(`od-rest/info/tradebook`, requestOptions).then();
}

function getTopSectors(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`curest/eqsector/scrips`, payload, requestOptions).then();
}

function getAlerts() {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.get(`alertrest/alert`, requestOptions).then();
}

function createAlert(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`alertrest/alert/create`, payload, requestOptions).then();
}

function cancelAlert(id) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.delete(`alertrest/alert/delete/${id}`, requestOptions).then();
}

function modifyAlert(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`alertrest/alert/update`, payload, requestOptions).then();
}

function sortMwList(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`mw-rest/advance/sortMwScrips`, payload, requestOptions).then();
}

function getETFDetails(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    // return AXIOS.post(`curest/etf/getdetails`, payload, requestOptions).then();
    return AXIOS.get(`common/etf`, requestOptions).then();
}

function getBasketData(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    // basketorder/report
    return AXIOS.get(`bo-rest/basketorder`, requestOptions).then();
}



function createBasket(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`bo-rest/basketorder/create`, payload, requestOptions).then();
}

function renameBasket(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`bo-rest/basketorder/rename`, payload, requestOptions).then();
}

function deleteBasket(id) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.delete(`bo-rest/basketorder/delete/${id}`, requestOptions).then();
}

function addScripInBasket(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`bo-rest/basketorder/add/scrips`, payload, requestOptions).then();
}

function getBasketScrips(basketId) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.get(`bo-rest/basketorder/get/scrips/${basketId}`, requestOptions).then();
}

function deleteScripInBasket(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`bo-rest/basketorder/delete/scrips`, payload, requestOptions).then();
}

// function inValidateSession(payload) {
//     const requestOptions = {
//         headers: authHeaderWs(),
//     };
//     return AXIOS.post(`http://154.83.3.25:8889/NorenWsHelper/InvalidateSess`, payload, requestOptions).then();
// }
function inValidateSession(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`client-rest/profile/invalidateWsSess`, payload, requestOptions).then();
}

// function createWsSession(payload) {
//     const requestOptions = {
//         headers: authHeaderWs(),
//     };
//     return AXIOS.post(`http://154.83.3.25:8889/NorenWsHelper/CreateSess`, payload, requestOptions).then();
// }
function createWsSession(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`client-rest/profile/createWsSess`, payload, requestOptions).then();
}


function productConvertion(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`po-rest/positions/conversion`, payload, requestOptions).then();
}

async function getProfileData() {
    const requestOptions = {
        headers: authHeader(),
    };
    return await AXIOS.get(`client-rest/profile/getclientdetails`, requestOptions).then();
}


function getOrderHistory(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`od-rest/info/history`, payload, requestOptions).then();
}

function getTickerTapeList(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`mw-rest/ticker-tape/get`, payload, requestOptions).then();
}

function getPredefinedMw(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`mw-rest/pre-def/mw/get`, payload, requestOptions).then();
}

function getOptionChain(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`obrest/optionChain/getOptionChain`, payload, requestOptions).then();
}

function getUnderlying(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`obrest/optionChain/getUnderlying`,payload, requestOptions).then();
}

function getUnderlyingExpiry(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`obrest/optionChain/getUnderlyingExp`, payload, requestOptions).then();
}

function getSecurityInfo(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`scrips-rest/scrip/security/info`, payload, requestOptions).then();
}

function getBrokerageDetails(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`brkcal/brokerage/calculation`, payload, requestOptions).then();
}

function squreOffAll(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`od-rest/orders/positions/sqroff`, payload, requestOptions).then();
}

function getWsSession() {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.get(`client-rest/profile/getUser`, requestOptions).then();
}

// function getBasketMargin(payload) {
//     const requestOptions = {
//         headers: authHeader(),
//     };
//     return AXIOS.post(`bo-rest/basketorder/spanmargin`, payload, requestOptions).then();
// }


function getBasketMargin(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`bo-rest/basketorder/getmargin`, payload, requestOptions).then();
}

function executeBasket(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`bo-rest/basketorder/execute`, payload, requestOptions).then();
}

function resetBasketExeStatus(basketId) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.get(`bo-rest/basketorder/reset/${basketId}`, requestOptions).then();
}

function changePassword(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`am/access/pwd/change`, payload, requestOptions).then();
}

function getPinnedScrips() {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.get(`client-rest/startbar/get`, requestOptions).then();
} 

function pinToHeader(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`client-rest/startbar/add`, payload, requestOptions).then();
}

function getAuthorizeHoldings() {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.get(`ho-rest/holdings/nonpoa/cnc`, requestOptions).then();
}

// function initAuthorize(payload) {
//     const requestOptions = {
//         headers: authHeader(),
//     };
//     return AXIOS.post(`ho-rest/edis/initialize`, payload, requestOptions).then();
// }

function submitFeedback(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`fbrest/feedback/sendFeedback`, payload, requestOptions).then();
}

function exitBoCoPosition(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`od-rest/orders/exit/sno`, payload, requestOptions).then();
}

function getExchangeStatus() {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.get(`alertrest/messages/exch/status`, requestOptions).then();
}

function getExchangeMsg(exchange) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.get(`alertrest/messages/exch?exchange=${exchange}`, requestOptions).then();
}

function getPopContent(payload){
    const requestOptions = {
      headers: { "content-type": "application/json" },
    };
    return AXIOS.post(`notify/notify/get/popup`, payload, requestOptions).then();
  }

function pledgeData(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`ho-rest/cdsl/pledgeData`, payload, requestOptions).then();
} 

function revokeData(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`ho-rest/cdsl/revocationData`, payload, requestOptions).then();
}

function getTopGainers() {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.get(`common/analysis/topgainers`, requestOptions).then();
}


function getTopLosers() {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.get(`common/analysis/toplosers`, requestOptions).then();
}

function payinApiCall(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`funds-rest/atompayment/create`, payload, requestOptions).then();
}

function payoutApiCall(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`funds-rest/atompayment/payout`, payload, requestOptions).then();
}

function getUserBankDetails() {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.get(`funds-rest/atompayment/get/bankdetails`, requestOptions).then();
}

function callHdfcPayment(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`hdfc-payment/payment/createTrans`,payload, requestOptions).then();
}

function checkHdfcPayment(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`hdfc-payment/payment/checkTransStatus`,payload, requestOptions).then();
}

function getEdis() {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.get(`ho-rest/edis/get/hstoken`, requestOptions).then();
}

function boRedirection() {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.get(`funds-rest/payment/bo/redirect`, requestOptions).then();
}

// Indian Indices
function getIndianIndices() {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.get(`common/indices/indian/get`, requestOptions)
}

// IPO
function getIPOMaster() {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.get(`iporest/ipo/masterlist`, requestOptions).then();
}

function getAnnouncedList() {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.get(`adrest/ipo/announcedList/get`, requestOptions).then();
}

function getRecentList() {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.get(`adrest/ipo/recentlist/get`, requestOptions).then();
}

function ongoingIpo() {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.get(`iporest/nse/order/openOrders`, requestOptions).then();
}

function editBasketScrip(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`bo-rest/basketorder/update/scrips`, payload, requestOptions).then();
}


/****
 * @author Bharath
 * @date 27/06/2024
 * @method advance_MW
 * ***/ 

function getAdvanceMw(payload){
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`mw-rest/advance/mw`, payload, requestOptions);
}
// GTT
function getGTTOrders() {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.get(`od-rest/info/gtt/orderbook`, requestOptions).then();
}

// SIP
function getSIPOrders() {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.get(`od-rest/info/sip/orderbook`, requestOptions).then();
}

/****
 * @author Arun
 * @date 19/07/2024
 * @method Razorpay
 * ***/ 

// create Payment
function createPayment_razorpay(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`funds-rest/razorPay/create`, payload, requestOptions).then();
}

// verify Payment
function verifyPayment_razorpay(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`funds-rest/razorPay/verify`, payload, requestOptions).then();
}

// screeners
// 52 week HIGH

function yearHigh() {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.get(`common/analysis/52weekhigh`, requestOptions).then();
}

function yearLow() {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.get(`common/analysis/52weeklow`, requestOptions).then();
}

function riders() {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.get(`common/analysis/riders`, requestOptions).then();
}

function draggers() {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.get(`common/analysis/draggers`, requestOptions).then();
}

function topvolume() {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.get(`common/analysis/topvolume`, requestOptions).then();
}

function meanreversion() {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.get(`common/analysis/meanreversion`, requestOptions).then();
}

function placeIpoOrder(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`iporest/nse/order/placeOrders`, payload, requestOptions).then();
}

function cancelIpoOrder(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`iporest/nse/order/cancelOrders`, payload, requestOptions).then();
}

function getAppliedIpo() {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.get(`iporest/nse/order/getOrderBook`, requestOptions).then();
}

function accFreezeStatus(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`client-rest/profile/freeze/status`, payload, requestOptions).then();
}

function sendFreezeOTP(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`client-rest/profile/freeze/otp`, payload, requestOptions).then();
}

function freezeOTPVerify(payload) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`client-rest/profile/freeze/otpvalidate/update`, payload, requestOptions).then();
}