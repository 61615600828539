import { service } from '../../../_services/gtt.services';
import errHandle from '../../../handleError/errorHandling'
import { useNotification } from "@kyvg/vue3-notification";
const { notify } = useNotification()

const getDefaultState = () => {
    return {    
        gttList:[],
        loader: false,
        isCancelGtt: false,
        gttCancelScrip: {},
        cancelLoader: false,
        gttInitialDialog: false
    }
}

const state = getDefaultState();

const actions = {
    async placeOcoOrder({ commit, dispatch }: any, payload: any) {
        commit("setLoader", true)
        await service.placeOcoOrder(payload).then((resp: any) => {
            if (resp.status == 200 && resp.data.status == "Ok" && resp.data.result[0]?.message == 'Order created') {
                notify({ group: 'auth', type: 'success', title: `GTT ${resp.data.result[0]?.message}` })
                dispatch("getGTTOrders")
            } else {
                notify({ group: 'auth', type: 'error', title: resp.data.message })
            }
        }, (error: any) => {
            errHandle.statusCodeCheck(error)
        }).finally(() => {
            commit("setIsGTT_ow", false, { root:true })
            commit("setLoader", false)
        })
    },

    async getGTTOrders({ commit,dispatch }:any) {
        commit('setLoader', true, { root: true })
        commit('setGTTList', [])
        await service.getGTTOrders().then(async(resp:any)=>{
            if (resp.status == 200 && resp.data.status == "Ok") {
                await commit('setGTTList', resp.data?.result)
                dispatch('order/setOrdersTabsCount', '', { root: true })
            }
        },(error) =>{
            errHandle.statusCodeCheck(error)
        }).finally(()=>{
            commit('setLoader', false, { root: true })
        })
    },

    async cancelGtt({ commit, dispatch }:any, payload:any) {
        commit('setCancelLoader', true)
        await service.cancelGtt(payload).then((resp:any)=>{
            if (resp.status == 200 && resp.data.status == "Ok" && resp.data.result[0]?.message == 'Order deleted successfully') {
                notify({ group: 'auth', type: 'success', title: `GTT ${resp.data.result[0]?.message}` })
                dispatch("getGTTOrders")
            } else {
                notify({ group: 'auth', type: 'error', title: resp.data.result[0]?.message })
            }
        },(error) =>{
            errHandle.statusCodeCheck(error)
        }).finally(()=>{
            commit('setCancelLoader', false)
        })
    },
    
    async modifyOcoOrder({ commit, dispatch }: any, payload: any) {
        commit("setLoader", true)
        await service.modifyOcoOrder(payload).then((resp: any) => {
            if (resp.status == 200 && resp.data.status == "Ok" && resp.data.result[0]?.message == 'Order Modified') {
                notify({ group: 'auth', type: 'success', title: `GTT ${resp.data.result[0]?.message}` })
                dispatch("getGTTOrders")
            } else {
                notify({ group: 'auth', type: 'error', title: resp.data.message })
            }
        }, (error: any) => {
            errHandle.statusCodeCheck(error)
        }).finally(() => {
            commit("setIsGTT_ow", false, { root:true })
            commit("setLoader", false)
        })
    },

    async ModifySingleOrder({ commit, dispatch }: any, payload: any) {
        commit("setLoader", true)
        await service.modifySingleOrder(payload).then((resp: any) => {
            if (resp.status == 200 && resp.data.status == "Ok") {
                notify({ group: 'auth', type: 'success', title: `GTT Order Modified` })
                dispatch("getGTTOrders")
            } else {
                notify({ group: 'auth', type: 'error', title: resp.data.result[0]?.message })
            }
        }, (error: any) => {
            errHandle.statusCodeCheck(error)
        }).finally(() => {
            commit("setIsGTT_ow", false, { root:true })
            commit("setLoader", false)
        })
    },

    async placeSingleOrder({ commit, dispatch }: any, payload: any) {
        commit("setLoader", true)
        await service.placeSingleOrder(payload).then((resp: any) => {
            if (resp.status == 200 && resp.data.status == "Ok" && resp.data.result[0]?.orderNo) {
                notify({ group: 'auth', type: 'success', title: `GTT Order Placed` })
                dispatch("getGTTOrders")
            } else {
                notify({ group: 'auth', type: 'error', title: resp.data.result[0]?.message })
            }
        }, (error: any) => {
            errHandle.statusCodeCheck(error)
        }).finally(() => {
            commit("setIsGTT_ow", false, { root:true })
            commit("setLoader", false)
        })
    },
}

const mutations = {
    setGTTList(state: { gttList:any[]}, payload:any){
        payload.forEach((el:any) => {
            el.qty = el.exchange == 'MCX' ? el.qty / el.lotSize : el.qty
            if(el.placeOrderParams) el.placeOrderParams.quantity = el.exchange == 'MCX' ? el.placeOrderParams.quantity / el.lotSize : el.placeOrderParams.quantity
            if(el.placeOrderParamsleg2) el.placeOrderParamsleg2.quantity = el.exchange == 'MCX' ? el.placeOrderParamsleg2.quantity / el.lotSize : el.placeOrderParamsleg2.quantity
            el.typeOfOrder = el.placeOrderParamsleg2 == null ? 'Single' : 'OCO'
            if (el.transType == "B") {
                // Swap the objects
                let temp = el.placeOrderParams;
                el.placeOrderParams = el.placeOrderParamsleg2;
                el.placeOrderParamsleg2 = temp;
            }
        });
        state.gttList = payload
    },
    setLoader(state:{ loader:boolean }, payload:boolean) {
        state.loader = payload
    },
    setIsCancelGtt(state: { isCancelGtt: boolean }, payload: boolean) {
        state.isCancelGtt = payload
    },
    setGttCancelScrip(state: { gttCancelScrip: boolean }, payload: boolean) {
        state.gttCancelScrip = payload
    },
    setCancelLoader(state: { cancelLoader:boolean}, payload:boolean) {
        state.cancelLoader = payload
    },
    setGttInitialDialog(state: { gttInitialDialog: boolean }, payload: boolean) {
        state.gttInitialDialog = payload
    },
}
const getters = {
    getLoader: (state: { loader:boolean }) => state.loader,
    getGTTList: (state: { gttList:any[] })=> state.gttList,
    getIsCancelGtt: (state: { isCancelGtt:boolean}) => state.isCancelGtt,
    getGttCancelScrip: (state: { gttCancelScrip: boolean }) => state.gttCancelScrip,
    getCancelLoader: (state: { cancelLoader:boolean}) => state.cancelLoader,
    getGttInitialDialog: (state: { gttInitialDialog:boolean}) => state.gttInitialDialog,
}

const gtt = {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}

export default gtt