import { commonService } from '../../../_services/common.service.js'
import errHandle from '../../../handleError/errorHandling.js'
import formula from '../../../mixins/formula.js'
import { useNotification } from "@kyvg/vue3-notification";
const { notify } = useNotification()
const state = {
    watchlistData: [],
    watchlist: [],
    tab: 0,
    mwSearchList: [],
    mwLoader: false,
    preDefineList: [],
    iniWatchList: [],
    activeListTab: 'Watch List',
    isInfoDialog: false,
    infoData: '',
    allWatchListData: [],
    activeTab: 0,
    tRowChange: false,
    depthIndex: -1,
    mwTabs: [
        { name: 'Watchlist', id: 0, active: true, count: '0', key: 'My Watchlist' },
        { name: 'Predefined list', id: 1, active: false, count: '0', key: 'Predefined Watchlist' },
        { name: 'Option chain', id: 2, active: false, count: '0', key: 'Option Chain' },
    ],
    mwSearchLoader: false,
    searchListForAlert:[],
    searchListForBasket: [],
    searchListForgtt: [],
    selectedMenu: ''
}
const mutations = {
    async setWatchList(state, payload) {
        state.watchlist = payload
    },
    setTab(state, payload) {
        state.tab = payload
    },
    setWatchListData(state, payload) {
        state.watchlistData = payload
    },
    setMwSearchList(state, payload) {
        state.mwSearchList = payload
    },
    setMwLoader(state, payload) {
        state.mwLoader = payload
    },
    setPreDefineList(state, payload) {
        state.preDefineList = payload
    },
    setIniWatchList(state, payload) {
        state.iniWatchList = payload
    },
    setActiveListTab(state, payload) {
        state.activeListTab = payload
    },
    setInfoDialog(state, payload) {
        state.isInfoDialog = payload
    },
    setInfoData(state, payload) {
        state.infoData = payload
    },
    setAllWatchListData(state, payload) {
        state.allWatchListData = payload
    },
    setActiveTab(state, payload) {
        state.activeTab = payload
    },
    setTRowChange(state, payload) {
        state.tRowChange = payload
    },
    setDepthIndex(state, payload) {
        state.depthIndex = payload
    },
    setMwTabs(state, key) {
        state.mwTabs.forEach(el => {
            if (el.key == key) {
                el.active = true
            } else {
                el.active = false
            }
        });
    },
    setMwSearchLoader(state, payload){
        state.mwSearchLoader = payload
    },
    setSearchListForAlert(state, payload) {
        state.searchListForAlert = payload
    },
    setSearchListForBasket(state, payload) {
        state.searchListForBasket = payload
    },
    setSelectedMenu(state, payload) {
        state.selectedMenu = payload
    },
    setSearchListForgtt(state, payload) {
        state.searchListForgtt = payload
    },
}
const actions = {
    resetState({ state }) {
        state.watchlist = state.mwSearchList = state.watchlistData = state.allWatchListData = []
        state.activeTab = state.tab = 0
        state.mwLoader = state.isInfoDialog = false
        state.infoData = ''
    },
    async getWatchList({ dispatch, commit , rootGetters}) {
        commit('setMwLoader', true)
        commit('setAllWatchListData', [])
        let json = {
            defaultMw: true,
            source: "WEB",
            preDef: true,
            advFlag: true,
            lstsFlag: true
        }
        await commonService.getAdvanceMw(json).then(async resp => {

            if (resp.status == 200 && (resp.data.status == "Not ok" || resp.data.status == "Ok")) {
                let mwList = resp.data.result.filter((pre) => !pre.preDef)
                let preDefList = resp.data.result.filter((pre) => pre.preDef)
                commit('setIniWatchList', mwList)
                commit('setPreDefineList', preDefList)
                commit('setActiveTab', 0)
                commit('setAllWatchListData', [...preDefList, ...mwList])
                await dispatch('setKeys', { data: rootGetters['pref/getMarketwatchType'] == 'My Watchlist' ? mwList : preDefList, where: 'initial', action: 'initial' })
            } else {
                notify({ group: 'auth', type: 'error', title: resp.data.message })
            }

        }, (err) => {
            errHandle.statusCodeCheck(err)
        }).finally(() => { commit('setMwLoader', false) })
    },
    async setKeys({ state, commit, dispatch, rootGetters }, payload) {

        
        let zeroVal = '0.00'
        let tempWatchList = state.watchlist
        let tempWatchlistData = state.watchlistData
        let ObjList = {}
        let tokenList = []
        let holdingsdata = rootGetters['holdings/getHoldingsData']
        if(holdingsdata && holdingsdata.length == 0 && sessionStorage.getItem('holdingsData') && sessionStorage.getItem('holdingsData') != "undefined"){
            holdingsdata = JSON.parse(sessionStorage.getItem('holdingsData'))
        }
        let activeIndex = state.tab
        if (payload.where == 'initial') {
            for (let list of payload.data) {
                let Obj = {}
                let tokenLst = []
                let isPref = list.preDef ? true : false
                if (list.scrips) {
                    for (let scrip of list.scrips) {
                        scrip = await updateValues(scrip, zeroVal, holdingsdata)
                        scrip.isPref = isPref
                        Obj[scrip.token] = scrip
                        tokenLst.push(scrip.token)
                    }
                } else {
                    list.scrips = []
                }
                list.Obj = Obj
                list.tokenList = tokenLst
            }
            commit('setWatchList', payload.data)
        }
        if (payload.where == 'add') {
            ObjList = tempWatchList[activeIndex].Obj
            tokenList = tempWatchList[activeIndex].tokenList
            let isPref = tempWatchList[activeIndex].preDef ? true : false
            for (let scrip of payload.data) {
                scrip = await updateValues(scrip, zeroVal, holdingsdata)
                scrip.isPref = isPref
                ObjList[scrip.token] = scrip
                tokenList.push(scrip.token)
            }
            let tempUpdateData =  rootGetters['pref/getSortPosition'] == '1' ? [...payload.data, ...tempWatchlistData] : [...tempWatchlistData, ...payload.data]
            tempWatchList[activeIndex].scrips = tempUpdateData
            tempWatchList[activeIndex].Obj = ObjList
            tempWatchList[activeIndex].tokenList = tokenList
            let sortArray = []
            for (let scrip of tempUpdateData) {
                let temp = {
                    exch: scrip.exchange,
                    token: scrip.token,
                    sortingOrder: tempUpdateData.indexOf(scrip)
                }
                sortArray.push(temp)
            }
            dispatch('saveSortListedArray', sortArray)
        }
        if (payload.where == 'del') {
            ObjList = tempWatchList[activeIndex].Obj
            const index = tempWatchList[activeIndex].scrips.findIndex(
                (std) => std.token === payload.data.token
            );
            if (index != -1) {
                delete ObjList[tempWatchList[activeIndex].scrips[index].token]
                tempWatchList[activeIndex].Obj = ObjList
                tempWatchList[activeIndex].scrips.splice(index, 1)
                tempWatchList[activeIndex].tokenList.splice(tempWatchList[activeIndex].tokenList.indexOf(payload.data.token), 1)
            }
        }
        if (payload.where == 'add' || payload.where == 'del') {
            commit('setWatchList', tempWatchList)
        }

        await dispatch('changeWatchList', payload.action)
    },
    async changeWatchList({ state, commit, dispatch , rootGetters}, payload) {
        let tempMwList = rootGetters['pref/getMarketwatchType'] == 'My Watchlist' ? state.iniWatchList : state.preDefineList
        let activeIndex = state.tab

        if (tempMwList.length > 0 && tempMwList[activeIndex]?.scrips) {
            commit('setWatchListData', tempMwList[activeIndex].scrips)
            if (payload != "update") {
                await dispatch('checkSocketStatus')
            }
        } else {
            commit('setWatchListData', [])
        }
        dispatch('update_reset_active_watchlist', 'update')
    },
    update_reset_active_watchlist({}, payload){
        if(payload){
            if(payload == 'update'){
                if (window.hasOwnProperty('updateList') && typeof window.updateList.callback === 'function'){
                    window.updateList.callback()
                }
            }
            if(payload == 'reset'){
                if (window.hasOwnProperty('resetCurList') && typeof window.resetCurList.callback === 'function'){
                    window.resetCurList.callback()
                }
            }
        }
    },

    getSearchList({ state, commit, dispatch ,rootGetters}, payload) {
        if(payload.where == 'mwatch'){
            commit('setMwSearchLoader', true)
        }
        if(payload.where == 'basket'){
            commit('basket/setSearchLoader', true, { root: true })
        }
        if(payload.where == 'gtt'){
            commit('gtt/setLoader', true, { root: true })
        }
        let json = {
            exchange: payload.where == 'mwatch' ? ["All"] : ["CDS", "MCX", "NFO", "NSE"],
            symbol: payload.value
        }
        commonService.getSearchList(json).then(resp => {
            if (resp.status == 200 && resp.data.status == "Ok" && resp.data.result) {
                let basketScrips = []
                    let compareList = payload.where == 'mwatch' ? state.watchlistData : rootGetters['basket/getBasketScrips']
                    resp.data.result.forEach((searchData) => {
                        if (searchData.segment != "INDEX") {
                            basketScrips.push(searchData)
                        }
                        if (searchData.segment == "INDEX") {
                            searchData.exchIndex = `${searchData.exchange}::index`
                        } else {
                            searchData.tradingSymbol = searchData.formattedInsName
                        }
                        compareList.forEach((mwListData) => {
                            if (mwListData["token"] == searchData["token"]) {
                                return searchData["checked"] = true;
                            }
                        })
                    })
                if (payload.where == 'alert') {
                    commit('setSearchListForAlert', resp.data.result)
                } else if (payload.where == 'basket') {
                    commit('setSearchListForBasket', basketScrips)
                } else if (payload.where == 'gtt') {
                    commit('setSearchListForgtt', basketScrips)
                } else {
                    commit('setMwSearchList', resp.data.result)
                }
            } else {
                commit('setMwSearchList', [])
                commit('setSearchListForAlert', [])
                commit('setSearchListForBasket', [])
                commit('setSearchListForgtt',[])
            }
        }, (err) => {
            errHandle.statusCodeCheck(err)
        }).finally(() => {
                commit('setMwSearchLoader', false)
                commit('basket/setSearchLoader', false, { root: true })
                commit('gtt/setLoader', false, { root: true })
        })
    },
    async addSearchList({ state, commit, dispatch, rootGetters }, payload) {

        let tempMwList = state.watchlist
        let activeIndex = state.tab
        let json = {
            userId: rootGetters['login/getUCC'],
            mwId: tempMwList[activeIndex].mwId,
            scripData: payload
        }
        await commonService.addScripList(json).then(async resp => {
            dispatch('update_reset_active_watchlist', 'reset')
            if (resp.status == 200 && resp.data.status == "Ok" && resp.data.message == "Success") {
                state.mwSearchList.forEach((el) => {
                    if (payload[0].token == el.token) {
                        return el.checked = true
                    }
                })
                commit('setMwSearchList', state.mwSearchList)
                await dispatch('setKeys', { data: resp.data.result, where: 'add', action: 'change' })
            } else {
                notify({ group: 'auth', type: 'error', title: resp.data.message })
                dispatch('update_reset_active_watchlist', 'update')
            }
        }, (err) => {
            errHandle.statusCodeCheck(err)
        }).finally(() => { })
    },
    async deleteSearchList({ state, dispatch, rootGetters }, payload) {
        let tempMwList = state.watchlist
        let activeIndex = state.tab
        let json = {
            userId: rootGetters['login/getUCC'],
            mwId: tempMwList[activeIndex].mwId,
            scripData: payload
        }
        commonService.deleteScripList(json).then(async resp => {
            dispatch('update_reset_active_watchlist', 'reset')
            if (resp.status == 200 && resp.data.status == "Ok" && resp.data.message == "Success") {
                await dispatch('setKeys', { data: payload[0], where: 'del', action: 'change' })
            } else {
                notify({ group: 'auth', type: 'error', title: resp.data.message })
                dispatch('update_reset_active_watchlist', 'update')
            }
        }, (err) => {
            errHandle.statusCodeCheck(err)
        }).finally(() => { })
    },
    async checkSocketStatus({ state, rootGetters, dispatch }, payload) {
        if (rootGetters['ws/getConnectionStatus']) {
            var tempData = {
                data: payload == 'unmounted' ? [] : state.watchlistData,
                where: 'mkWatch'
            }
            await dispatch("ws/websocketSubscription", tempData, { root: true })
        }
    },
    async saveSortListedArray({ state, dispatch, rootGetters }, payload) {
        let json = {
            userId: rootGetters['login/getUCC'],
            mwId: state.watchlist[state.tab].mwId,
            scripData: payload,
            mwName: state.watchlist[state.tab].mwName,
        }
        commonService.sortMwList(json).then(async resp => {
            dispatch('update_reset_active_watchlist', 'reset')
            if (resp.status == 200 && resp.data.status == "Ok" && resp.data.message == "Success") {
                await dispatch('setKeys', { data: state.watchlist, where: 'initial', action: 'sort' })
            } else {
                notify({ group: 'auth', type: 'error', title: resp.data.message })
                dispatch('update_reset_active_watchlist', 'update')
            }
        }, (err) => {
            errHandle.statusCodeCheck(err)
        }).finally(() => { })
    },
    async getScripInfoData({ commit, dispatch }, payload) {
        let json = {
            token: payload.token,
            exch: payload.exchange
        }

        httpService.getScripInfo(json).then(resp => {
            let scripInfo = ''
            if (resp.status == 200 && resp.data.status == "Ok" && resp.data.message == "Success" && resp.data.result && resp.data.result.length > 0) {
                for (let item of resp.data.result) {
                    if (item.hasOwnProperty('secInfo') && item.secInfo.length == 1) {
                        item.secInfo[0].sRemarks ? '' : item.secInfo[0].sRemarks = 'NA'
                        item.secInfo[0].hasOwnProperty('nExDate') && item.secInfo[0].nExDate > 0 ? item.secInfo[0].nExDate = [('0' + new Date(item.secInfo[0].nExDate * 1000).getDate()).slice(-2), ('0' + (new Date(item.secInfo[0].nExDate * 1000).getMonth() + 1)).slice(-2), new Date(item.secInfo[0].nExDate * 1000).getFullYear()].join('-') : item.secInfo[0].nExDate = 'Nil'
                        item.secInfo[0].hasOwnProperty('nRecordDate') && item.secInfo[0].nRecordDate > 0 ? item.secInfo[0].nRecordDate = [('0' + new Date(item.secInfo[0].nRecordDate * 1000).getDate()).slice(-2), ('0' + (new Date(item.secInfo[0].nRecordDate * 1000).getMonth() + 1)).slice(-2), new Date(item.secInfo[0].nRecordDate * 1000).getFullYear()].join('-') : item.secInfo[0].nRecordDate = 'Nil'
                        item.secInfo[0].hasOwnProperty('nBookClosureEndDate') && item.secInfo[0].nBookClosureEndDate > 0 ? item.secInfo[0].nBookClosureEndDate = [('0' + new Date(item.secInfo[0].nBookClosureEndDate * 1000).getDate()).slice(-2), ('0' + (new Date(item.secInfo[0].nBookClosureEndDate * 1000).getMonth() + 1)).slice(-2), new Date(item.secInfo[0].nBookClosureEndDate * 1000).getFullYear()].join('-') : item.secInfo[0].nBookClosureEndDate = 'Nil'
                        item.secInfo[0].hasOwnProperty('nBookClosureStartDate') && item.secInfo[0].nBookClosureStartDate > 0 ? item.secInfo[0].nBookClosureStartDate = [('0' + new Date(item.secInfo[0].nBookClosureStartDate * 1000).getDate()).slice(-2), ('0' + (new Date(item.secInfo[0].nBookClosureStartDate * 1000).getMonth() + 1)).slice(-2), new Date(item.secInfo[0].nBookClosureStartDate * 1000).getFullYear()].join('-') : item.secInfo[0].nBookClosureStartDate = 'Nil'
                        scripInfo = { ...payload, ...item.secInfo[0] }
                        commit('setInfoDialog', true)
                    }
                }
            } else {
                notify({ group: 'auth', type: 'error', title: resp.data.message })
            }

            commit('setInfoData', scripInfo)
        }, (err) => {
            errHandle.statusCodeCheck(err)
        }).finally(() => { })
    }
}
const getters = {
    getCurrentWatchList: (state) => state.watchlistData,
    getTrowChange: (state) => state.tRowChange,
    getNorWatchlist:(state) => state.iniWatchList,
    getPrefWatchlist:(state) => state.preDefineList,
    getMwListLoader:(state)=> state.mwLoader,
    getMwTabs: (state)=> state.mwTabs,
    getSearchListForBasket: (state) => state.searchListForBasket,
    getSearchListForAlert: state => state.searchListForAlert,
    getSelectedMenu: state => state.selectedMenu,
    getSearchListForgtt: state => state.searchListForgtt
}


const watchlist = {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters,
}

export default watchlist



async function updateValues(scrip, zeroVal, holdList) {
    if (scrip.segment && scrip.segment.includes('_idx')) {
        scrip.exchIndex = `${scrip.exchange}::index`
    }
    holdList.forEach((el) => {
        if (el.exchange == scrip.exchange && el.token == scrip.token) {
            scrip.holdQty = el.netQty
        }
    })
    scrip.pdc = scrip.pdc ? scrip.pdc : 0

    /**  ADD Old key***/ 
    scrip.wsToken = `${scrip?.exchange}|${scrip?.token}`
    scrip.nameshowActions = false
    scrip.hover = false
    scrip.showMore = false
    scrip.showActions = false
    scrip.isOptionsExpanded = false
/************* ------------------------------ ********/

    let tickerData = window.tickers
    let key = `${scrip.exchange}|${scrip.token}`
    scrip.o = tickerData && tickerData[key] && tickerData[key].o ? tickerData[key].o : zeroVal
    scrip.h = tickerData && tickerData[key] && tickerData[key].h ? tickerData[key].h : zeroVal
    scrip.l = tickerData && tickerData[key] && tickerData[key].l ? tickerData[key].l : zeroVal
    scrip.c = tickerData && tickerData[key] && tickerData[key].c ? tickerData[key].c : zeroVal
    scrip.pdc = tickerData && tickerData[key] && tickerData[key].c ? tickerData[key].c : scrip.pdc
    scrip.lp = tickerData && tickerData[key] && tickerData[key].lp ? tickerData[key].lp : scrip.pdc
    scrip.chg = formula.getChg(scrip.lp, scrip.pdc, scrip.exchange)
    scrip.pchg = tickerData && tickerData[key] && tickerData[key].pc ? tickerData[key].pc : zeroVal
    scrip.BPrice1 = tickerData && tickerData[key] && tickerData[key].bp1 ? tickerData[key].bp1 : zeroVal
    scrip.BPrice2 = tickerData && tickerData[key] && tickerData[key].bp2 ? tickerData[key].bp2 : zeroVal
    scrip.BPrice3 = tickerData && tickerData[key] && tickerData[key].bp3 ? tickerData[key].bp3 : zeroVal
    scrip.BPrice4 = tickerData && tickerData[key] && tickerData[key].bp4 ? tickerData[key].bp4 : zeroVal
    scrip.BPrice5 = tickerData && tickerData[key] && tickerData[key].bp5 ? tickerData[key].bp5 : zeroVal
    scrip.BOrders1 = tickerData && tickerData[key] && tickerData[key].bo1 ? tickerData[key].bo1 : zeroVal
    scrip.BOrders2 = tickerData && tickerData[key] && tickerData[key].bo2 ? tickerData[key].bo2 : zeroVal
    scrip.BOrders3 = tickerData && tickerData[key] && tickerData[key].bo3 ? tickerData[key].bo3 : zeroVal
    scrip.BOrders4 = tickerData && tickerData[key] && tickerData[key].bo4 ? tickerData[key].bo4 : zeroVal
    scrip.BOrders5 = tickerData && tickerData[key] && tickerData[key].bo5 ? tickerData[key].bo5 : zeroVal
    scrip.BQty1 = tickerData && tickerData[key] && tickerData[key].bq1 ? tickerData[key].bq1 : zeroVal
    scrip.BQty2 = tickerData && tickerData[key] && tickerData[key].bq2 ? tickerData[key].bq2 : zeroVal
    scrip.BQty3 = tickerData && tickerData[key] && tickerData[key].bq3 ? tickerData[key].bq3 : zeroVal
    scrip.BQty4 = tickerData && tickerData[key] && tickerData[key].bq4 ? tickerData[key].bq4 : zeroVal
    scrip.BQty5 = tickerData && tickerData[key] && tickerData[key].bq5 ? tickerData[key].bq5 : zeroVal
    scrip.totalbuyqty = tickerData && tickerData[key] && tickerData[key].tbq ? tickerData[key].tbq : zeroVal
    scrip.SPrice1 = tickerData && tickerData[key] && tickerData[key].sp1 ? tickerData[key].sp1 : zeroVal
    scrip.SPrice2 = tickerData && tickerData[key] && tickerData[key].sp2 ? tickerData[key].sp2 : zeroVal
    scrip.SPrice3 = tickerData && tickerData[key] && tickerData[key].sp3 ? tickerData[key].sp3 : zeroVal
    scrip.SPrice4 = tickerData && tickerData[key] && tickerData[key].sp4 ? tickerData[key].sp4 : zeroVal
    scrip.SPrice5 = tickerData && tickerData[key] && tickerData[key].sp5 ? tickerData[key].sp5 : zeroVal
    scrip.SOrders1 = tickerData && tickerData[key] && tickerData[key].so1 ? tickerData[key].so1 : zeroVal
    scrip.SOrders2 = tickerData && tickerData[key] && tickerData[key].so2 ? tickerData[key].so2 : zeroVal
    scrip.SOrders3 = tickerData && tickerData[key] && tickerData[key].so3 ? tickerData[key].so3 : zeroVal
    scrip.SOrders4 = tickerData && tickerData[key] && tickerData[key].so4 ? tickerData[key].so4 : zeroVal
    scrip.SOrders5 = tickerData && tickerData[key] && tickerData[key].so5 ? tickerData[key].so5 : zeroVal
    scrip.SQty1 = tickerData && tickerData[key] && tickerData[key].sq1 ? tickerData[key].sq1 : zeroVal
    scrip.SQty2 = tickerData && tickerData[key] && tickerData[key].sq2 ? tickerData[key].sq2 : zeroVal
    scrip.SQty3 = tickerData && tickerData[key] && tickerData[key].sq3 ? tickerData[key].sq3 : zeroVal
    scrip.SQty4 = tickerData && tickerData[key] && tickerData[key].sq4 ? tickerData[key].sq4 : zeroVal
    scrip.SQty5 = tickerData && tickerData[key] && tickerData[key].sq5 ? tickerData[key].sq5 : zeroVal
    scrip.totalsellqty = tickerData && tickerData[key] && tickerData[key].tsq ? tickerData[key].tsq : zeroVal
    scrip.v = tickerData && tickerData[key] && tickerData[key].v ? tickerData[key].v : zeroVal
    scrip.lc = tickerData && tickerData[key] && tickerData[key].lc ? tickerData[key].lc : zeroVal
    scrip.uc = tickerData && tickerData[key] && tickerData[key].uc ? tickerData[key].uc : zeroVal
    scrip.ltt = tickerData && tickerData[key] && tickerData[key].ltt ? tickerData[key].ltt : zeroVal
    scrip.ltq = tickerData && tickerData[key] && tickerData[key].ltq ? tickerData[key].ltq : zeroVal
    scrip.weightedavg = tickerData && tickerData[key] && tickerData[key].ap ? tickerData[key].ap : zeroVal
    scrip.oi = tickerData && tickerData[key] && tickerData[key].oi ? tickerData[key].oi : zeroVal
    scrip['52wh'] = tickerData && tickerData[key] && tickerData[key]['52h'] ? tickerData[key]['52h'] : zeroVal
    scrip['52wl'] = tickerData && tickerData[key] && tickerData[key]['52l'] ? tickerData[key]['52l'] : zeroVal
    return scrip
}