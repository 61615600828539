import { commonService } from '../../../_services/common.service';
import commonJs from '../../../mixins/common'
import errHandle from '../../../handleError/errorHandling'
import { useNotification } from "@kyvg/vue3-notification";
const { notify } = useNotification()

function multiplyLot(qty:any, info:any) {
    let lotSize = info?.lotSizeView
    let exch = info?.exchange
    let mqty = exch == 'MCX' ? (Number(qty) * Number(lotSize)) : qty
    return Math.abs(mqty)
}


const getDefaultState = () => {
    return {
        orderType: "Regular",
        priceType: 'MKT',
        pcodes: [],
        isPrice: false,
        isTrigprice: false,
        priceRadioGroup: [],
        contractInfo: '',
        contractInfoFull: '',
        // 
        qtyModel: 0,
        priceModel: 0,
        trigPriceModel: 0,
        discQtyModel: 0,
        targetPriceModel: 0,
        marginData: [],
        isAddOrExit: '',
        currentScripData: '',
        currentPage: '',
        isModify: false,
        isRepeat: false,
        validity: 'DAY',
        owSubToken: '',
        currentLtp: '',
        currentLtpForPrice: '',
        isLocalPreference: false,
        isLoader: false,
        productCode: 'MIS',
        validityGroup: [
            { name: 'Day', value:'Day', id: 0 , active: true, tooltip: 'Regular day order' },
            { name: 'IOC', value:'IOC', id: 0 , active: false, tooltip: 'Immediate or Cancel' }
        ],
        currentLtpPchg: '0.00',
        currentLtpChg: '0.00',
        isVisible: false,
        isNudge: false,
        nudgeData: [],
        isAsmGsmConfirm: false,
        isBoChecked: false,
        orderTypes:[
            { name: 'Quick', key:'Quick', id: 0, active: true, tooltip: '' },
            { name: 'Regular', key:'Regular',id: 1, active: false, tooltip: '' },
            { name: 'Cover', key:'CO', id: 2, active: false, tooltip: '' },
            // { name: 'Bracket', key:'BO', id: 2, active: false, tooltip: '' },
            { name: 'AMO', key:'AMO', id: 3, active: false, tooltip: '' },
        ],
        isSecondLeg: false,
        isSecondLegOpen: false,
        isTargetChecked: false,

        // GTT STATES
        // 
        gtt_orderTypes:[
            { name: 'GTT', key:'gtt', id: 0, active: true, tooltip: '' },
        ],
        gtt_priceTypes : [
            { name: 'Single', value: 'single', id: 'single_gtt', active: true, tooltip: 'Single' },
            { name: 'OCO', value: 'oco', id: 'oco_gtt', active: false, tooltip: 'OCD' },
        ],
        gttPriceType: "Single",
        gtt_targetQty: 0,
        gtt_targetPrice: 0,
        gtt_target_trigger_price: 0,
        percentage : 5,
        ocoPercentage : 5,
    }
}

const state = getDefaultState();

const actions = {
    async getContractInfo({ commit, rootGetters, rootState, dispatch }:any, payload:any) {
        try {
            let json = {
                "token": payload.token,
                "exch": payload.exchange
            }
            let response = await commonService.getContractInfo(json)
            if (response.status == 200 && response.data?.result && response.data.status != 'Not ok') {
                await dispatch('setContractInfo', { data: response.data?.result , from : payload?.from, exchange: payload.exchange })
                if(!rootGetters['getIsConvertDialog']) {
                    commit('setIsVisible', true)
                    rootState.buysellpopup.moreOption = false
                }
            } else {
                notify({ group: 'auth', type: 'error', title: `${response.data.message}` })
            }
        } catch (error) {
            errHandle.statusCodeCheck(error)
        }
    },

    async changeOrderByTab({ state, commit }:any) {
        commonJs.focusQty()
        switch (state.orderType) {
            case "Quick": {
                if (state.priceType == "L") {
                    commit('setIsPrice', true)
                } else if (state.priceType == "MKT") {
                    commit('setIsPrice', false)
                }
                commit("setTrigPriceModel", state.priceModel)
                break;
            }  
            case "Regular":
            case "AMO": {
                if (state.priceType == "L") {
                    commit('setIsPrice', true)
                    commit('setIsTrigprice', false)
                } else if (state.priceType == "MKT") {
                    commit('setIsPrice', false)
                    commit('setIsTrigprice', false)
                } else if (state.priceType == "SL") {
                    commit('setIsPrice', true)
                    commit('setIsTrigprice', true)
                } else if (state.priceType == "SL-M") {
                    commit('setIsPrice', false)
                    commit('setIsTrigprice', true)
                }
                commit("setTrigPriceModel", state.priceModel)
                break;
            }
            case "CO": {
                if (state.priceType == "L") {
                    commit('setIsPrice', true)
                    commit('setIsTrigprice', true)
                } else if (state.priceType == "MKT") {
                    commit('setIsPrice', false)
                    commit('setIsTrigprice', true)
                } else if (state.priceType == "SL") {
                    commit('setIsPrice', true)
                    commit('setIsTrigprice', true)
                }
                break;
            }
            case "BO": {
                // bracket Order validation
                if (state.priceType == "L") {
                    commit('setIsPrice', true)
                    commit('setIsTrigprice', true)
                } else if (state.priceType == "SL") {
                    commit('setIsPrice', true)
                    commit('setIsTrigprice', true)
                } else if (state.priceType == "MKT") {
                    commit('setIsPrice', false)
                    commit('setIsTrigprice', true)
                }
                break;
            }
        }
    },

    async getMargin({ state, commit, rootGetters }:any) {
        commit('setMarginLoader', true)
        try {
            let orderType = state.orderType == 'CO' ? 'Cover' : state.orderType == 'BO' ? 'Bracket' : state.orderType == 'Quick' ? 'Regular' : state.orderType
            let exch = state.contractInfo.exchange
            let json:any = {
                "exchange": exch,
                "token": state.contractInfo.token,
                "tradingSymbol": state.contractInfo.tradingSymbol,
                "qty": multiplyLot(state.qtyModel , state.contractInfo),
                "price":  state.priceModel,
                "product": state.productCode,
                "transType": rootGetters['getBuysellpopup'].bsselected === 0 ? 'B' : 'S',
                "priceType": state?.priceType,
                "orderType": orderType,
                "triggerPrice": state.trigPriceModel,
                "stopLoss": state.trigPriceModel,
                "ltp": orderType == 'Cover' && state.priceType == "MKT" ? state.currentLtp : "",
                "snonum": (orderType == "Bracket" || orderType == "Cover") && state.isModify
                    ? state.currentScripData?.snoOrderNumber
                    : undefined,
                "orderNo": (orderType == 'Bracket' || orderType == 'Cover') && state.isModify
                    ? state.currentScripData?.orderNo
                    : undefined,
            }
            if(orderType == 'Cover' || orderType == 'Bracket') {
                json["target"] = state.targetPriceModel
            }
            if(state.isModify && state.currentPage != 'basket') {
                json["openOrderQty"] = state.currentScripData?.qty
                json["openOrderprice"] = state.currentScripData?.price
                if(state.currentScripData?.triggerPrice)
                json["openOrderTriggerPrice"] = state.currentScripData?.triggerPrice
            }
            let response = await commonService.getMargin(json)
            if (response.status == 200 && response.data?.result) {
                commit('setMarginData', response.data?.result)
            } else {
                commit('setMarginData', [])
            }
        } catch (error) {
            commit('setMarginLoader', false)
            commit('setMarginData', [])
            errHandle.statusCodeCheck(error)
        }
        commit('setMarginLoader', false)
    },

    async placeOrder({ state, commit, dispatch, rootGetters }:any) {
        try {
            commit('setIsLoader', true)
            let orderType = state.orderType == 'CO' ? 'Cover' : state.orderType == 'BO' ? 'Bracket' : state.orderType == 'Quick' ? 'Regular' : state.orderType
            let json:any = [{
                "exchange": state.contractInfo.exchange,
                "tradingSymbol": state.contractInfo.tradingSymbol,
                "qty": multiplyLot(state.qtyModel , state.contractInfo),
                "price": state.isPrice ? state.priceModel : 0,
                "product": state.productCode,
                "transType": rootGetters['getBuysellpopup'].bsselected === 0 ? 'B' : 'S',
                "priceType": state.priceType,
                "orderType" : orderType,
                "ret": state.validity?.toUpperCase(),
                "triggerPrice": state.isTrigprice ? state.trigPriceModel : 0,
                "disclosedQty": state?.discQtyModel,
                "mktProtection": "",
                "target": !state.targetPriceModel ? 0 : state.targetPriceModel,
                "stopLoss": !state.trigPriceModel ? 0 : state.trigPriceModel,
                "trailingPrice": 0,
                "source" : "WEB",
                "token" : state.contractInfo.token,
                "remarks" : state.orderType,
                "deviceNumber" : rootGetters['login/getDeviceNumber']
            }]
            if(state.currentPage == 'basket' && rootGetters['basket/getBasketOwType'] != 'Edit') {
                json[0]["token"] = state.contractInfo.token
                dispatch('basket/addScripInBasket', json, { root:true })
            }else if(state.currentPage == 'basket' && rootGetters['basket/getBasketOwType'] == 'Edit') {
                json[0]["token"] = state.contractInfo.token
                json[0]["id"] = rootGetters['basket/getCurrentScripData'].id
                json[0]["lotSize"] = state.contractInfo.lotSize
                dispatch('basket/editScripInBasket', json, { root:true })
            } else {
                let response = await commonService.placeOrder(json)
                if (response.status == 200 && response.data[0].message == "Success") { }
                else if(response.status == 200 && response.data[0].message == "Unauthorized") {
                    errHandle.unauthorizeHandle()
                } else {
                    notify({ group: "auth",  clean: true });
                    notify({ group: 'auth', type: 'error', title: `${ response.data[0].message }` })
                }
            }
        } catch (error) {
            commit('setIsLoader', false)
            errHandle.statusCodeCheck(error)
        }
        commit('setIsLoader', false)
    },

    async modifyOrder({ state, commit, rootGetters }:any) {
        try {
            commit('setIsLoader', true)
            let json = {
                "exchange": state.contractInfo.exchange,
                "tradingSymbol": state.contractInfo.tradingSymbol,
                "orderNo": state.currentScripData?.orderNo,
                "qty": multiplyLot(state.qtyModel, state.contractInfo)?.toString(),
                "ret": state.validity?.toUpperCase(),
                "priceType": state.priceType,
                "transType": rootGetters['getBuysellpopup'].bsselected === 0 ? 'B' : 'S',
                "price":  state.isPrice ? state.priceModel?.toString() : '',
                "product": state.productCode,
                "triggerPrice": (state.orderType == 'CO' || state.orderType == "BO") && !state.isSecondLeg || state.trigPriceModel == 0 ? '' : state.isTrigprice ? state.trigPriceModel : '',
                "disclosedQty": state?.discQtyModel,
                "mktProtection": "",
                "target": state.targetPriceModel == 0 ? '' : state.targetPriceModel,
                "stopLoss": state.trigPriceModel == 0 ? '' : state.trigPriceModel,
                "trailingPrice": '',
                "token" : state.contractInfo.token,                
                "deviceNumber" : rootGetters['login/getDeviceNumber']
            }
            let response = await commonService.modifyOrder(json)
            if (response.status == 200 && response.data.message == "Success") { }
            else if (response.status == 200 && response.data[0].message == "Unauthorized") {
                errHandle.unauthorizeHandle()
            } else {
                notify({ group: "auth",  clean: true });
                notify({ group: 'auth', type: 'error', title: `${ response.data[0].message }` })
            }
        } catch (error) {
            commit('setIsLoader', false)
            errHandle.statusCodeCheck(error)
        }
        commit('setIsLoader', false)
    },

    async unsubScribe({ dispatch, rootGetters }:any) {
        if (rootGetters['ws/getOtherSubscription']) {
            let temp = {
                sub: rootGetters['ws/getOtherSubscription'],
                stage: 'esc'
            }
            dispatch('ws/ortherSubscription', temp, { root: true })
        }
    },

    async wsOwSubscription({ state, commit, dispatch }:any) {
        var tempData = {
            sub: `${state.contractInfo.exchange}|${state.contractInfo.token}`,
            stage: 'initial'
        }
        commit('setOwSubToken',tempData.sub)
        dispatch('ws/ortherSubscription', tempData, { root: true })
    },

    async setOrderPreference({ state, commit, dispatch }:any) {
        if(localStorage.getItem('orderPreference') && state.currentPage != 'positions' && state.currentPage != 'holdings') {
            let { variety, product, pcode } = JSON.parse(localStorage.getItem('orderPreference')!)
                if(variety && product && pcode ) {
                    dispatch('setPriceType', product)
                    await dispatch('setOrderType', variety ? variety : 'Regular')
                    if (pcode == 'NRML' && (state.currentExchange == 'NSE' || state.currentExchange == 'BSE')) {
                        pcode = 'CNC'
                    } else if (pcode == 'CNC' && (state.currentExchange != 'NSE' && state.currentExchange != 'BSE')) {
                        pcode = 'NRML'
                    }
                    dispatch('setProductCode', pcode)
                } else {
                    dispatch('defaultPreference')
                }
        } else {
            dispatch('defaultPreference')
        }
        commit('setTargetPrice', 0)
    },

    async defaultPreference({ state, commit, dispatch }:any) {
        if(state.currentPage == 'positions') {
            await dispatch('setOrderType', 'Regular')
            dispatch('setPriceType', 'MKT')
            dispatch('setProductCode', state.currentScripData.product || 'MIS')
        } else if(state.currentPage == 'holdings') {
            await dispatch('setOrderType', 'Regular')
            dispatch('setPriceType', 'MKT')
            dispatch('setProductCode', "CNC")
        } else {
            await dispatch('setOrderType', 'Regular')
            dispatch('setPriceType', 'L')
            dispatch('setProductCode', "MIS")
        }
        commit('setTargetPrice', 0)
    },

    modifyPreference({ state, commit, dispatch }:any) {
        const ot = state.currentScripData?.orderType
        const ctrigerPrice = state.currentScripData?.triggerPrice
        let prcType =state.currentScripData?.priceType
        
        // if(ot == 'Regular' && state.currentScripData?.priceType == 'L' && state.currentScripData?.triggerPrice) {
        //     prcType = 'SL'
        // } else {
        //     prcType = state.currentScripData?.priceType
        // }
        dispatch('setOrderType', ot == 'Cover' ? 'CO' : ot == 'Bracket' ? 'BO' : ot)
        dispatch('setPriceType', prcType)
        dispatch('setProductCode', state.currentScripData?.product)
        // 
        if (ot == 'Cover') {
            commit('setTrigPriceModel', ctrigerPrice) 
        }
        if (ot == 'Bracket') {
            commit('setTargetPrice', state.currentScripData?.target)
            commit('setTrigPriceModel', state.isSecondLegOpen ? '' : ctrigerPrice)
        }
        
        if(ot != 'Cover' && ot != 'Bracket') {
            commit('setTrigPriceModel', 0) 
        }
        if(ot != 'Bracket') {
            commit('setTargetPrice', 0)
        } 
    },

    repeatPreference({state, commit,dispatch}:any) {
        const ot = state.currentScripData?.orderType
        const ctrigerPrice = state.currentScripData?.triggerPrice
        const isQuick = state.currentScripData?.remarks == "Quick"
        dispatch('setOrderType', isQuick ? 'Quick' : ot == 'Cover' ? 'CO' : ot == 'Bracket' ? 'BO' : ot)
        dispatch('setPriceType', state.currentScripData?.priceType)
        dispatch('setProductCode', state.currentScripData?.product)

        if(ot == 'Cover') {
            commit('setTrigPriceModel', ctrigerPrice)
        } 
        if (ot == 'Bracket') {
            commit('setTargetPrice', state.currentScripData?.target)
            commit('setTrigPriceModel', ctrigerPrice)
        } 

        if(ot != 'Cover' && ot != 'Bracket') {
            commit('setTrigPriceModel', 0) 
        }
        if(ot != 'Bracket') {
            commit('setTargetPrice', 0)
        }
    },

    async getBrokerageDetails({ state, commit, rootGetters }:any) {
        try {
            commit('brokerage/setLoader', true, { root : true })
            let qty = state.qtyModel?.toString();
            let json = {
                "user": rootGetters['login/getUCC'],
                "price": String(parseFloat(state.priceModel).toFixed(2)),
                "qty": qty,
                "token": String(state.contractInfo.token),
                "lotSize": String(state.contractInfo.lotSize),
                "plan": "IBT",
                "segment": state.currentExchange,
                "type": state.productCode == 'MIS' ? 'INTRADAY' : 'DELIVERY' ,
                "instrumentType": state.currentExchange == "NSE" || state.currentExchange == "BSE" ? "EQ" : state.contractInfo.insType,
                "transactionType": rootGetters['getBuysellpopup'].bsselected === 0 ? 'buy' : 'sell',
                "symbol": state.contractInfo.symbol
            }
            let resp = await commonService.getBrokerageDetails(json)
            commit('brokerage/setBrokeragePayload', { data:json, ci: state.contractInfo, priceType: state.priceType }, { root: true })
            if (resp.data.message == "Success") {
                commit('brokerage/setBrokerageDetails', resp.data.result[0], { root: true })
            } else {
                commit('brokerage/setBrokerageDetails', [], { root: true })
            }
        } catch (error) {
            commit('brokerage/setLoader', false, { root : true })
            errHandle.statusCodeCheck(error)
        }
        commit('brokerage/setLoader', false, { root : true })
    },

    async setDefaultValues({ state, commit, dispatch }:any) {
        dispatch('setDefaultPrice')
        dispatch('setDefaultTrigPrice')
        // if(state.currentScripData?.orderType == 'Cover') {
        //     dispatch('setDefaultStopLossPrice')
        // }
        let qty = state.contractInfo.lotSize
        let discQty = 0
        if (state.isAddOrExit == 'exit' && state.currentPage == 'holdings') {
            qty = state.currentScripData?.netQty
        } else if (state.isAddOrExit == 'exit' && state.currentPage == 'positions') {
            qty = state.currentScripData?.netQty
        }
        qty = !qty ? 0 : state.currentExchange == 'MCX' ? (qty/qty) : qty
        if(state.isRepeat || state.isModify) {
            qty = state.currentScripData.exchange == "MCX" ?  state.currentScripData?.qty /state.currentScripData?.lotSize : state.currentScripData?.qty
            discQty = state.currentScripData.disclosedQty
        }
        commit('setQtyModel', Math.abs(parseFloat(qty)))
        commit('setGtt_targetQty', Math.abs(parseFloat(qty)))
        commit('setDiscQtyModel', discQty)
        commonJs.focusQty()
        if(state.isAddOrExit == 'gttModify') {
            dispatch('handleGttOrder', 'modify')
        } else if(state.isAddOrExit == 'gtt') {
            dispatch('handleGttOrder', 'normal')
        }
    },

    async setDefaultPrice({state, commit }:any) {
        // set default price
        if(state.currentLtpForPrice == '' && !state.isModify && !state.isRepeat) {
            commit('setPriceModel', commonJs.toFixed(state.contractInfo.pdc))
        } else {
            if(state.isModify || state.isRepeat && state.priceType != "MKT" && state.priceType != "SL-M") {
                commit('setPriceModel', state.currentScripData?.price)
            } else {
                commit('setPriceModel', commonJs.toFixed(state.currentLtpForPrice))
            }
        }
    },

    async setDefaultTrigPrice({state, commit}:any) {
        // set default trigger price
        if(state.currentLtpForPrice == '' && !state.isModify && !state.isRepeat) {
            commit('setTrigPriceModel', commonJs.toFixed(state.contractInfo.pdc))
        } else {
            if(state.isModify || state.isRepeat) {
                commit('setTrigPriceModel', state.isSecondLegOpen ? '' : !state.isSecondLeg && (state.orderType == 'BO' || state.orderType == 'CO' ) ?  state.currentScripData?.stopLoss : state.currentScripData?.triggerPrice ? state.currentScripData?.triggerPrice :  state.currentLtpForPrice)
            } else {
                // commit('setTrigPriceModel', commonJs.toFixed(state.currentLtpForPrice))
                commit('setTrigPriceModel', state.orderType != 'CO' && state.orderType != 'BO' ? state.priceModel : 0)
            }
        }
        commit('setGtt_target_triggerPrice', commonJs.toFixed(state.currentLtpForPrice))
        commit('setGtt_targetPrice', commonJs.toFixed(state.currentLtpForPrice))
    },

    // async setDefaultStopLossPrice({state, commit}:any) {
    //     if(state.isModify) {
    //         commit('setTrigPriceModel', state.currentScripData?.stopLoss ? state.currentScripData?.stopLoss :  0)
    //     } else {
    //         commit('setTrigPriceModel', 0)
    //     }
    // },

    // mutaion converted actions
    async setOrderType({state, commit, dispatch}:any, payload:any) {
        state.orderType = payload
        // if(payload != 'GTT'){
        state.orderTypes.forEach((el:any) => {
            el.active = el.key === payload
        });
        // }
        if(state.orderType && state.orderType !=  'Quick') {
            await dispatch('setPriceRadioGroup', state.orderType)
        }
        await commit("setIsBoChecked", state.orderType == "BO");
        if(payload == 'Quick')
        dispatch('handleQuickOrder')
    },

    async setPriceRadioGroup({ state, dispatch }:any, orderType:any) {
        let regularData = [{ name: 'Limit', value: 'L', id: 'bs-Limit', active: false, tooltip: 'at preferred price' },
        { name: 'Market', value: 'MKT', id: 'bs-Market', active: true, tooltip: 'at Market price' },
        { name: 'SL', value: 'SL', id: 'bs-Stop-Loss', active: false, tooltip: 'at preferred price with a stoploss' },
        { name: 'SL-M', value: 'SL-M', id: 'bs-SL-M', active: false, tooltip: 'at market price with stoploss' }]

        let coData = [{ name: 'Limit', value: 'L', id: 'bs-Limit', active: false, tooltip: 'at preferred price' },
        { name: 'Market', value: 'MKT', id: 'bs-Market', active: true, tooltip: 'at Market price' },
        // { name: 'SL', value: 'SL', id: 'bs-Stop-Loss', active: false, tooltip: 'at preferred price with a stoploss' },
        ]

        let boData = [{ name: 'Limit', value: 'L', id: 'bs-Limit', active: false , tooltip: 'at preferred price'},
        { name: 'Market', value: 'MKT', id: 'bs-Market', active: true, tooltip: 'at Market price' },
        // { name: 'SL', value: 'SL', id: 'bs-Stop-Loss', active: false, tooltip: 'at preferred price with a stoploss'},
        ]

        let amoData = [{ name: 'Limit', value: 'L', id: 'bs-Limit', active: false, tooltip: 'at preferred price' },
        { name: 'Market', value: 'MKT', id: 'bs-Market', active: true, tooltip: 'at Market price' },
        { name: 'SL', value: 'SL', id: 'bs-Stop-Loss' , active: false, tooltip: 'at preferred price with a stoploss' }]

        let secondLegCoData = [{ name: 'Limit', value: 'L', id: 'bs-Limit', active: false, tooltip: 'at preferred price' },
            { name: 'Market', value: 'MKT', id: 'bs-Market', active: true, tooltip: 'at Market price' },
            { name: 'SL', value: 'SL', id: 'bs-Stop-Loss', active: false, tooltip: 'at preferred price with a stoploss' },
        ]

        let secondLegBoData = [{ name: 'Limit', value: 'L', id: 'bs-Limit', active: false, tooltip: 'at preferred price' },
            { name: 'Market', value: 'MKT', id: 'bs-Market', active: true, tooltip: 'at Market price' },
            { name: 'SL', value: 'SL', id: 'bs-Stop-Loss', active: false, tooltip: 'at preferred price with a stoploss' },
        ]

        // let gttData = [
        //     { name: 'Single', value: 'single', id: 'single_gtt', active: true, tooltip: 'Single' },
        //     { name: 'OCO', value: 'oco', id: 'oco_gtt', active: false, tooltip: 'OCD' },
        // ]
        if (orderType == 'Regular') {
            state.priceRadioGroup = regularData;
        } else if (orderType == 'CO' && !state.isSecondLeg) {
            state.priceRadioGroup = coData;
        } else if (orderType == 'BO' && !state.isSecondLeg) {
            state.priceRadioGroup = boData;
        } else if (orderType == 'AMO') {
            state.priceRadioGroup = amoData;
        } else if(orderType == 'CO' && state.isSecondLeg && state.isModify) {
            state.priceRadioGroup = secondLegCoData;
        } else if(orderType == 'BO' && state.isSecondLeg && state.isModify) {
            state.priceRadioGroup = secondLegBoData;
        }
        //  else if(orderType == 'GTT'){
        //     state.priceRadioGroup = gttData
        // }
        // let optType = state.contractInfo.insType
        // let isNFO = (((state.currentExchange == 'NFO' || state.currentExchange == 'BFO') && optType == "OPTIDX") || state.currentExchange == 'CDS' || state.currentExchange == 'BCD') && (state.orderType != 'BO' && state.orderType != 'CO')
        // if(isNFO) {
        //     state.priceRadioGroup = amoData
        //     if(orderType == 'CO' || orderType == 'BO' ) {
        //         state.priceRadioGroup[2].disabled = true
        //     }
        // }

        let priceType = ''
        state.priceRadioGroup.forEach((el:any) => {
            if(el.active) {
                priceType = el.value
            }
            if(state.currentExchange == 'MCX' && state.isModify && (el.name == "SL" || el.name == "SL-M") ) {
                el.disabled = true
            } else {
                el.disabled = false
            }
        });
        let localPref =  JSON.parse(localStorage.getItem('orderPreference')!)
        if(state.currentPage != 'positions' && state.currentPage != 'holdings' && !state.isRepeat && !state.isModify) {
            if(localPref?.product) {
                await dispatch('setPriceType', localPref?.product)
            } else {
                await dispatch('setPriceType', priceType)
            }
        }
    },

    async setContractInfo({state, commit, dispatch}:any, payload:any) {
        const isSno = state.currentScripData.snoOrderNumber
        const priceType = state.currentScripData.priceType
        if(state.isModify && isSno) {
            await commit('setIsSecondLeg', true)
        } else {
            await commit('setIsSecondLeg', false)
        }

        if(state.isModify && isSno && priceType == 'L') {
            await commit('setIsSecondLegOpen', true)
        } else {
            await commit('setIsSecondLegOpen', false)
        }

        await commit('setContractInfo', payload)
        await commit('setContractInfoFull', payload.data)
        if(state.contractInfo.prompt) {
            await commit('setNudgeData', state.contractInfo.prompt)
            await commit('setIsNudge', true)
        } else {
            await commit('setNudgeData', [])
            await commit('setIsNudge', false)
        }
        await commit('setCurrentExchange', state.contractInfo.exchange)
        await commit('setCurrentLtpForPrice', '')
        await dispatch('wsOwSubscription')
        await commit('setPcodes')
        // ow enbale old
        
        // set preference
        let localPref =  localStorage.getItem('orderPreference')
        if (state.isLocalPreference && localPref && !state.isModify && !state.isRepeat) {
            await dispatch("setOrderPreference")
        } else if(state.isModify) {
            await dispatch('modifyPreference')
        } else if(state.isRepeat) {
            await dispatch('repeatPreference')
        } else if(!state.isModify && !state.isRepeat) {
            await dispatch('defaultPreference')
        }
        let tickers = (window as any).tickers
        let ltp = 0 ;
        let token = state.contractInfo.token
        let exchange = state.contractInfo.exchange
        let wsToken = `${exchange}|${token}`
        if(tickers && tickers[wsToken]) tickers[wsToken].lp = tickers[wsToken]?.lp ? tickers[wsToken]?.lp : tickers[wsToken]?.c
        if(tickers && tickers[wsToken]?.lp) {
            ltp = tickers[wsToken]?.lp
        }
        ltp = ltp || state.contractInfo.pdc
        if(ltp) {
            await commit('setCurrentLtp', ltp)
            await commit('setCurrentLtpForPrice', ltp)
        }
        // commit('setTargetPrice', 0)

        // ow enable new
        if(payload.from != 'convert' && payload.from != 'gtt') {
            errHandle.closeAllModals()
            await commit('setIsGTT_ow', false, { root:true })
            await commit('setIsOpenOw', true, { root:true })
        }else if(payload.from == 'gtt'){
            await commit('setIsOpenOw', false, { root:true })
            await commit('setIsGTT_ow', true, { root:true })
        }
    },

    async setProductCode({ state, commit }:any, payload:any) {
        state.productCode = payload
        await commit('setPcodeActive', payload)
    },

    setValidity({ state, commit }:any, payload:any) {
        state.validity = payload
        commit('setValidityActive', payload)
    },

    async setPriceType({ state, commit }:any, payload:any) {
        state.priceType = payload
        await commit('setPriceTypeActive', payload)
    },
    async handleQuickOrder({ state, dispatch }:any) {
        if(state.priceType == 'SL' || state.priceType == 'SL-M') {
            dispatch("setPriceType", "MKT")
        }
        dispatch("setValidity", "DAY")
    },

    async handleGttOrder({ state, commit }:any, type:any) {
        const cd = state.currentScripData
        state.gtt_priceTypes.forEach((el:any,index:number) => {
            if(type == 'modify')el.active = cd.typeOfOrder == el.name 
            else el.active = index == 0
            if (el.active) {
                state.gttPriceType = el.value
            }
        });
        if(type == 'modify') {
            const qty = cd.typeOfOrder == "Single" ? cd.qty : cd.placeOrderParamsleg2.quantity
            const price = cd.typeOfOrder == "Single" ? cd.price : cd.placeOrderParamsleg2.price
            const trigPrice = cd.typeOfOrder == "Single" ? cd.gttValue : cd.placeOrderParamsleg2.triggerPrice
            commit("setQtyModel", qty)
            commit("setPriceModel", price)
            commit("setTrigPriceModel", trigPrice)
            const p = commonJs.getGttPercentage(price, trigPrice, state.contractInfo.tickSize)
            commit("setPercentage", p)
            if(cd.typeOfOrder == 'OCO') {
                commit('setGtt_targetQty', cd.placeOrderParams.quantity)
                commit("setGtt_targetPrice", cd.placeOrderParams.price)
                commit("setGtt_target_triggerPrice", cd.placeOrderParams.triggerPrice)
                const tp = commonJs.getGttPercentage(cd.placeOrderParams.price, cd.placeOrderParams.triggerPrice, state.contractInfo.tickSize)
                commit("setOcoPercentage", tp)
            }
        } else {
            let calculatedPrc = commonJs.getGttPrice(state.contractInfo.pdc, -5, state.contractInfo.tickSize)
            commit("setQtyModel", state.contractInfo.lotSize)
            commit("setPriceModel", state.contractInfo.pdc)
            commit("setTrigPriceModel", calculatedPrc)
            const p = commonJs.getGttPercentage(state.contractInfo.pdc, calculatedPrc, state.contractInfo.tickSize)
            commit("setPercentage", p)

            let calculatedTarPrc = commonJs.getGttPrice(state.contractInfo.pdc, 5, state.contractInfo.tickSize)
            commit('setGtt_targetQty', state.contractInfo.lotSize)
            commit("setGtt_targetPrice", state.contractInfo.pdc)
            commit("setGtt_target_triggerPrice", calculatedTarPrc)
            const tp = commonJs.getGttPercentage(state.contractInfo.pdc, calculatedTarPrc, state.contractInfo.tickSize)
            commit("setOcoPercentage", tp)
        }
    }
}

const mutations = {
    setPcodes(state:any) {
        state.pcodes = []
        let exchange = state.contractInfo.exchange
        state.pcodes.push({ name:'Intraday', key: 'MIS', id:0, active: false, tooltip: 'Margin Intraday Squareoff: Requires lower margin. Has to be exited before market close.' })
        let obj = exchange == 'NSE' || exchange == 'BSE' ? { name:'Long Term', key: 'CNC', id:1, active: true, tooltip: 'CashNCarry: Longterm investment.Requires full upfront margin' } : { name:'Positional', key: 'NRML', id:1, active: true, tooltip: 'Normal: Carry forward positions overnight.' }
        state.pcodes.push(obj)
    },

    setIsPrice(state:any, payload:any) {
        state.isPrice = payload
    },

    setIsTrigprice(state:any, payload:any) {
        state.isTrigprice = payload
    },

    setQtyModel(state:any, payload:any) {
        state.qtyModel = payload
    },

    setPriceModel(state:any, payload:any) {
        state.priceModel = payload
    },

    setTrigPriceModel(state:any, payload:any) {
        state.trigPriceModel = payload
    },

    setDiscQtyModel(state:any, payload:any) {
        state.discQtyModel = payload
    },

    setMarginData(state:any, payload:any) {
        if(payload.length != 0){
            state.marginData = payload[0]
        }
    },

    setIsAddOrExit(state:any, payload:any) {
        state.isAddOrExit = payload
        if (payload == 'modify') {
            state.isModify = true
        } else {
            state.isModify = false
        }
        if (payload == 'repeat') {
            state.isRepeat = true
        } else {
            state.isRepeat = false
        }
    },

    setCurrentScripData(state:any, payload:any) {
        state.currentScripData = payload
    },

    setCurrentPage(state:any, payload:any) {
        state.currentPage = payload
    },

    setTargetPrice(state:any, payload:any) {
        state.targetPriceModel = payload
    },
    
    setOwSubToken(state:any, payload:any) {
        state.owSubToken = payload
    },

    setCurrentLtp(state:any, payload:any) {
        state.currentLtp = payload
    },

    setCurrentLtpForPrice(state:any, payload:any) {
        state.currentLtpForPrice = payload
    },

    setIsLocalPreference(state:any, payload:any) {
        state.isLocalPreference = payload
    },

    setCurrentExchange(state:any, payload:any) {
        state.currentExchange = payload
    },

    setMarginLoader(state:any, payload:any) {
        state.marginLoader = payload
    },
    setIsLoader(state:any, payload:any) {
        state.isLoader = payload
    },

    setPriceTypeActive(state:any, value:any) {
        let data = state.priceRadioGroup
        if(!data?.length) return;
        let found = false;
        data.forEach((el:any) => {
            el.active = (el.value == value);
            if (el.active) {
                state.priceType = el.value
                found = true
            }
        });
        if (!found && data.length > 0) {
            data[0].active = true;
            state.priceType = data[0].value;
        }
    },

    setValidityActive(state:any, value:any) {
        if(!state.validityGroup.length) return;
        state.validityGroup.forEach((el:any) => {
            if (value == el.name) {
                el.active = true
                state.validity = el.value
            } else {
                el.active = false
            }
        });
    },

    setPcodeActive(state:any, key:any) {
        state.pcodes.forEach((el:any) => {
            if (key == el.key) {
                el.active = true
                state.productCode = el.key
            } else {
                el.active = false
            }
        });
    },

    setCurrentLtpPchg(state:any, payload:any) {
        state.currentLtpPchg = payload
    },

    setCurrentLtpChg(state:any, payload:any) {
        state.currentLtpChg = payload
    },

    setIsTargetChecked(state:any, payload:any) {
        state.isTargetChecked = payload
    },

    setIsVisible(state:any, payload:any){
        state.isVisible = payload
    },
    setIsNudge(state:any, payload:any) {
        state.isNudge = payload
    },
    setNudgeData(state:any, payload:any) {
        state.nudgeData = payload
    },
    setIsAsmGsmConfirm(state:any, payload:any) {
        state.isAsmGsmConfirm = payload
    },
    reset_state(state:any) {
        Object.assign(state, getDefaultState());
    },
    setIsBoChecked(state:any, payload:any) {
        state.isBoChecked = payload
    },
    setIsSecondLeg(state:any, payload:any) {
        state.isSecondLeg = payload
    },
    setIsSecondLegOpen(state:any, payload:any) {
        state.isSecondLegOpen = payload
    },

    setContractInfo(state:any, payload:any) {
        if(payload.data && payload.data.length != 0 && payload.data[0]?.scrips.length != 0) {
            let findedScrip = payload.data[0].scrips.find((item:any) => item.exchange == payload.exchange);
            let qty  = findedScrip.lotSize;
            let exch = findedScrip.exchange;
            let lot = !qty ? 0 : exch == "MCX" ? qty / qty : qty;
            findedScrip.lotSizeView = qty
            findedScrip.lotSize = lot
            state.contractInfo = findedScrip
        }
    },
    setContractInfoFull(state:any, payload:any) {
        if(payload.length != 0) {
            state.contractInfoFull = payload[0]
        }
    },
    setGtt_targetQty(state:any, payload:any){
        state.gtt_targetQty = payload
    },
    setGtt_targetPrice(state:any, payload:any){
        state.gtt_targetPrice = payload
    },
    setGtt_target_triggerPrice(state:any, payload:any){
        state.gtt_target_trigger_price = payload
    },

    setGttPriceTypeActive(state:any, value:any) {
        let data = state.gtt_priceTypes
        if(!data?.length) return;
        data.forEach((el:any) => {
            el.active = (el.value == value);
            if (el.active) {
                state.gttPriceType = el.value
            }
        });
    },

    setPercentage(state:{ percentage:any}, payload:any) {
        state.percentage = payload
    },

    setOcoPercentage(state:{ ocoPercentage:any}, payload:any) {
        state.ocoPercentage = payload
    }
}

const getters = {
    getOrderType: (state:{ orderType:any }) => state.orderType,
    getPriceType: (state:{ priceType:any }) => state.priceType,
    getIsPrice: (state: { isPrice:any }) => state.isPrice,
    getIsTrigprice: (state: { isTrigprice:any }) => state.isTrigprice,
    getPriceRadioGroup: (state:{priceRadioGroup:any}) => state.priceRadioGroup,
    getContractInfo: (state:{contractInfo:any}) => state.contractInfo,
    getQtyModel: (state:{qtyModel:any}) => state.qtyModel,
    getPriceModel: (state:{priceModel:any}) => state.priceModel,
    getTrigPriceModel: (state:{trigPriceModel:any}) => state.trigPriceModel,
    getMarginData: (state:{marginData:any}) => state.marginData,
    getIsModify: (state:{isModify:any}) => state.isModify,
    getTargetPrice: (state:{targetPriceModel:any}) => state.targetPriceModel,
    getValidity: (state:{validity:any}) => state.validity,
    getLotSize: (state:{contractInfo:any}) => state.contractInfo.lotSize,
    getOwSubToken: (state:{owSubToken:any}) => state.owSubToken,
    getCurrentLtp: (state:{currentLtp:any}) => state.currentLtp,
    getPcodes: (state:{pcodes:any}) => state.pcodes,
    currentExchange: (state:{currentExchange:any}) => state.currentExchange,
    marginLoader: (state:{marginLoader:any}) => state.marginLoader,
    isLoader: (state:{isLoader:any}) => state.isLoader,
    getValidityGroup: (state:{validityGroup:any}) => state.validityGroup,
    getProductCode: (state:{productCode:any}) => state.productCode,
    getCurrentLtpPchg: (state:{currentLtpPchg:any}) => state.currentLtpPchg,
    getCurrentLtpChg: (state:{currentLtpChg:any}) => state.currentLtpChg,
    getCurrentLtpForPrice: (state:{currentLtpForPrice:any}) => state.currentLtpForPrice,
    getIsNudge: (state:{isNudge:any}) => state.isNudge,
    getNudgeData : (state:{nudgeData:any}) => state.nudgeData,
    getIsAsmGsmConfirm: (state:{isAsmGsmConfirm:any}) => state.isAsmGsmConfirm,
    getIsBoChecked: (state:{isBoChecked:any}) => state.isBoChecked,
    getOrderTypes: (state:{orderTypes:any}) => state.orderTypes,
    getIsSecondLeg: (state:{isSecondLeg:any}) => state.isSecondLeg,
    getIsSecondLegOpen: (state:{isSecondLegOpen:any}) => state.isSecondLegOpen,
    getContractInfoFull: (state:{contractInfoFull:any}) => state.contractInfoFull,
    getIsTargetChecked: (state:{isTargetChecked:any}) => state.isTargetChecked,
    getDiscQtyModel: (state:{discQtyModel:any}) => state.discQtyModel,
    getIsRepeat: (state:{isRepeat:any}) => state.isRepeat,
    getCurrentScripData: (state:{currentScripData:any}) => state.currentScripData,
    getGTTOrderTypes: (state:{gtt_orderTypes:any}) => state.gtt_orderTypes,
    getGtt_targetPrice: (state:any)=> state.gtt_targetPrice,
    getGtt_target_triggerPrice: (state:any)=> state.gtt_target_trigger_price,
    getGttPriceRadioGroup: (state:{ gtt_priceTypes:any}) => state.gtt_priceTypes,
    getGttPriceType: (state: { gttPriceType:any }) => state.gttPriceType,
    getCurrentPage: (state: { currentPage:any }) => state.currentPage,
    getMarginLoader: (state: { marginLoader:any }) => state.marginLoader,
    getGtt_targetQty: (state: { gtt_targetQty:any }) => state.gtt_targetQty,
    getIsGttModify: ( state: { isAddOrExit:any }) => state.isAddOrExit == 'gttModify',
    getPercentage: (state: { percentage:any}) => state.percentage,
    getOcoPercentage: (state: { ocoPercentage:any }) =>state.ocoPercentage
}

const ow = {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}

export default ow