import router from '../router';
import store from '../_store'
import { useNotification } from "@kyvg/vue3-notification";
const { notify } = useNotification()

async function unauthorizeHandle(header, error, type) {
    let mobile = localStorage.getItem('mobileNo') && localStorage.getItem('mobileNo') != 'undefined' ? JSON.parse(localStorage.getItem('mobileNo')) : ''
    if(type == "login") {
        await store.commit('login/setStage', 'mobileNo')
        mobile ? store.dispatch('login/verifyClient', { mobileNo: mobile }) : ''
        notify({ group: 'auth', type: 'error', title: `${error || 'Session Expired'}` })
    }
    if (store.state.login.unauthorizeCount == 1) return false
    if (header != 'hide' && type != 'logout' && type != 'router' &&  type != 'navigateSso') {
        notify({ group: 'auth', type: 'error', title: `${error || 'Session Expired'}` })
    }
    // let mobile = localStorage.getItem('mobileNo') && localStorage.getItem('mobileNo') != 'undefined' ? JSON.parse(localStorage.getItem('mobileNo')) : ''
    let isExist = localStorage.getItem('isExistUser') && localStorage.getItem('isExistUser') != 'undefined' ? JSON.parse(localStorage.getItem('isExistUser')) : ''
    localStorage.clear()
    sessionStorage.clear()
    store.commit('login/setUnauthorizeCount', 1, { root: true })
    //
    store.dispatch('ws/websocketClose')
    store.dispatch('osf_ws/websocketClose')
    await closeAllModals()
    await resetAllStates()
    document.documentElement.classList.remove('dark')
    if(store.state.login.ssoQuery) {
        router.push({ path: "/", query: store.state.login.ssoQuery })
    } else {
        router.push("/")
    } 
    mobile ? store.dispatch('login/verifyClient', { mobileNo: mobile }) : ''
}

async function statusCodeCheck(error, from) {    
    let status = error?.response?.status
    let otherErrors = ''
    if (status == 401) {
        unauthorizeHandle(error, '', from);
        return false;
    }
    if (status == 502 || status == 404 || status == 500 ) {
        notify({ group: 'auth', type: 'error', title: `Something went wrong please try again later` })
        return false;
    }
    if (status) {
        notify({ group: 'auth', type: 'error', title: `(${status}) ${error?.response.statusText || error?.response}` })
    } else {
        otherErrors = error?.message
        notify({ group: 'auth', type: 'error', title: `${otherErrors}` })
    }
}

async function closeAllModals() {
    store.commit('setCancelOrderDialog', false, { root: true })
    store.commit('setExitPositionDialog', false, { root: true })
    store.commit('setCancelAlertDialog', false, { root: true })
    store.commit('setCreateBaskettDialog', false, { root: true })
    store.commit('setIsErrorDialog', false, { root: true })
    store.commit('brokerage/setIsBrokerageWindow', false, { root: true })
    store.commit('setIsMwInfoDialog', false, { root: true })
    store.commit('alert/setIsAlertDialog', { boolean: false }, { root: true })
    store.commit('setSignOutDialog', false, { root: true })
    store.commit('setSignOutDialog', false, { root: true })
    store.commit('setIsConvertDialog', false, { root: true })
    store.commit('setIsPositionInfo', false, { root: true })
    store.commit('holdings/setIsEdisDialog', false, { root: true })
    store.commit('setIsOpenOw', false, { root: true })
    store.commit('setIsGTT_ow', false, { root: true })
    store.commit('gtt/setIsCancelGtt', false, { root: true })
}

async function resetAllStates() {
    store.commit('reset_state', false, { root: true }) //1//
    store.commit('alert/reset_state', false, { root: true }) //2//
    // store.commit('login/reset_state', false, { root : true }) //3//
    store.commit('basket/reset_state', false, { root: true }) //4//
    store.commit('brokerage/reset_state', false, { root: true }) //5//
    store.commit('chart/reset_state', false, { root: true }) //6//
    store.commit('pref/reset_state', false, { root: true }) //7//
    store.commit('feedback/reset_state', false, { root: true }) //8//
    store.commit('funds/reset_state', false, { root: true }) //9//
    store.commit('holdings/reset_state', false, { root: true }) //10//
    store.commit('home/reset_state', false, { root: true }) //11//
    store.commit('oc/reset_state', false, { root: true }) //13//
    store.commit('order/reset_state', false, { root: true }) //14//
    store.commit('ow/reset_state', false, { root: true }) //15//
    store.commit('positions/reset_state', false, { root: true }) //17//
    store.commit('sector/reset_state', false, { root: true }) //18//
    store.commit('util/reset_state', false, { root: true }) //19//
    store.commit('ctabs/reset_state', false, { root: true }) //21//


}

async function otherErrHandle(error) {
    if (error.response.status == 502) {
        notify({ group: 'auth', type: 'error', title: 'Something went wrong please try again later' })
    }
}

export default { unauthorizeHandle, statusCodeCheck, closeAllModals, otherErrHandle }