import { commonService } from '../../../_services/common.service';
import formula from '../../../mixins/formula'
import errHandle from '../../../handleError/errorHandling'
import common from '../../../mixins/common'
import { useNotification } from "@kyvg/vue3-notification";
const { notify } = useNotification()

const state = {
    positionsData: [],
    conversionErr: '',
    objectPositionsList: '',
    isLoader: false,
    stringPositionList:[],
    nfoData: [],
    mcxData: [],
    nseData: []
}

const actions = {
    async getUserPositions({ state, commit, dispatch, rootGetters }, payload) {
        try {
            commit('setLoader', true, { root: true })
            let response = await commonService.getUserPositions()
            if (response.status == 200 && response.data?.result) {
                commit('setPositions', response.data?.result)
            }
        } catch (error) {
            errHandle.statusCodeCheck(error)
        }
        commit('setLoader', false, { root: true })
    },

    async checkWebsocketStatus({ state, dispatch, rootGetters }) {
        if (rootGetters['ws/getConnectionStatus']) {
            var tempData = {
                data: state.positionsData || [],
                where: 'positions'
            }
            dispatch("ws/websocketSubscription", tempData, { root: true })
        }
    },

    async productConversion({ state, dispatch, commit, rootGetters }, payload) {
        try {
            commit('setIsLoader', true)
            let response = await commonService.productConvertion(payload)
            if (response.status == 200 && response.data.status != 'Not ok') {
                dispatch('getUserPositions')
                commit('setIsConvertDialog', false, { root: true })
            } else {
                notify({ group: 'auth', type: 'error', title: `${response.data.message}` })
                state.conversionErr = response.data.message
            }
        } catch (error) {
            commit('setIsLoader', false)
            errHandle.statusCodeCheck(error)
        }
        commit('setIsLoader', false)
    },

    async positionSqureoffAll({ state, commit, dispatch, rootGetters, }, payload) {
        try {
            commit('setIsLoader', true)
            let response = await commonService.squreOffAll(payload)
            if (response.status == 200 && response.data[0].message == "Success") {
                commit('setExitPositionDialog', false, { root: true })
            } else {
                notify({ group: 'auth', type: 'error', title: `${response.data[0].message}` })
            }
        } catch (error) {
            commit('setIsLoader', false)
            errHandle.statusCodeCheck(error)
        }
        commit('setIsLoader', false)
    },

    async positionTabsCount({ state, commit, dispatch, rootGetters, }, payload) {
        this.dispatch('order/setOrdersTabsCount', {
            open: rootGetters['order/getOpenOrders'],
            exec: rootGetters['order/getExcutedOrders'],
            tradeBook : rootGetters['order/getTradeBook'],
            positions: rootGetters['positions/getPositionsData'],
        }, { root: true })
    },
};

const mutations = {
    setPositions(state, payload) {
        let tickers = window.tickers
        if (payload) {
            var holeObj = {}
            var nfoArr = []
            var mcxArr = []
            var nseArr = []
            payload.forEach((el,idx) => {
                el.id = idx
                el.wsToken = `${el?.exchange}|${el?.token}`
                if(tickers) {
                    el.ltp = tickers[el.wsToken]?.lp ? tickers[el.wsToken]?.lp : el.ltp
                }
                el.netBuyAvgPrice = parseFloat(el.netBuyAvgPrice).toFixed(el.exchange == "CDS" || el.exchange == "BCD" ? 4 : 2)
                el.netSellAvgPrice = parseFloat(el.netSellAvgPrice).toFixed(el.exchange == "CDS" || el.exchange == "BCD" ? 4 : 2)
                holeObj[el.token] = el
                if(el.exchange == 'MCX') {
                    mcxArr.push(el)
                    el.netQty = common.getLotSize(el.lotsize, el.netQty, el.exchange)
                    el.buyQty = common.getLotSize(el.lotsize, el.buyQty, el.exchange)
                    el.sellQty = common.getLotSize(el.lotsize, el.sellQty, el.exchange)
                }

                if(el.exchange == 'NFO') {
                    nfoArr.push(el)
                }
                if(el.exchange == 'NSE') {
                    nseArr.push(el)
                }
                // chart keys
                el.tradingSymbol = el?.tradingsymbol

                el.chg = Number(el.ltp - el.pdc).toFixed(2)
                // if(Number(el.netQty) == 0) {
                //     el.pnl = el?.realizedPnl
                //     el.MtoM = el?.realizedPnl
                // } else {
                    el.pnl = formula.realisedProfitLoss(el, 'pnl') + formula.unRealisedProfitLoss(el, 'pnl')
                    el.MtoM = formula.realisedProfitLoss(el, 'mtm') + formula.unRealisedProfitLoss(el, 'mtm')
                // }
                el.pnl = common.ruppesFormat(parseFloat(el.pnl).toFixed(2))
                el.MtoM = common.ruppesFormat(parseFloat(el.MtoM).toFixed(2))
                el.netPnl = 0
            });
        }
        state.objectPositionsList = holeObj
        payload.sort((a, b) => {
            if (Number(a.netQty) === 0 && Number(b.netQty) !== 0) {
                return 1;
            } else if (Number(a.netQty) !== 0 && Number(b.netQty) === 0) {
                return -1;
            } else {
                return 0;
            }
        });
        nfoArr.sort((a,b) => (Number(a.netQty) > Number(b.netQty)) ? -1 : ((Number(b.netQty) > Number(a.netQty)) ? 1 : 0))
        mcxArr.sort((a,b) => (Number(a.netQty) > Number(b.netQty)) ? -1 : ((Number(b.netQty) > Number(a.netQty)) ? 1 : 0))
        this.commit('positions/setStringPositionList' , payload)
        this.commit('positions/setPositionsData', payload)
        this.commit('positions/setNfoData', nfoArr)
        this.commit('positions/setMcxData', mcxArr)
        // this.commit('positions/setNseData', nseArr)
        this.dispatch('positions/checkWebsocketStatus')
        this.dispatch('order/setPositionTabsCount', { 'all': payload, 'nfo': nfoArr, 'mcx' : mcxArr })
    },
    setPositionsData(state, payload) {
        state.positionsData = payload
    },
    setIsLoader(state, payload) {
        state.isLoader = payload
    },
    setStringPositionList(state, payload){
        state.stringPositionList = payload
    },
    setNfoData(state, payload) {
        state.nfoData = payload
    },
    setMcxData(state, payload) {
        state.mcxData = payload
    },
    setNseData(state, payload) {
        state.nseData = payload
    },
    reset_state(state) {
        Object.assign(state,{
            positionsData: [],
            conversionErr: '',
            objectPositionsList: '',
            isLoader: false,
            stringPositionList:[],
            nfoData: [],
            mcxData: [],
            nseData: []
        });
    },
};

const getters = {
    getPositionsData: (state) => state.positionsData,
    getConversionErr: (state) => state.conversionErr,
    getObjectPositionsList: (state) => state.objectPositionsList,
    getIsLoader: (state) => state.isLoader,
    getNfoData: (state) => state.nfoData,
    getMcxData: (state) => state.mcxData,
    getNseData: (state) => state.nseData
};

const positions = {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}

export default positions