import store from "../../../_store";
import route from "../../../router";
import { env } from '../../../_helpers/env_index'
import errorHandling from "../../../handleError/errorHandling";
export default {

  methods: {
    async chartListener(event) {
      const msgData = event.data.data;
      if (event.data.id === "ChartPopout" && route.currentRoute.value.path == "/chart") {
        let prevData = store.state.chart.popOutIframeData
        var data = store.state.chart.tradeChartData;
        data.showPopout = false;
        data.isSymbolSearch = false;
      
        data.preloadProps = {
          interval: msgData.interval,
          indicators: msgData.indicators,
        };
        let url = window.location.protocol == "https:" ? env().depolyedUrl : env().localUrl;
        let iframeUrl = url + '?data=' + JSON.stringify(data);
        let newWindowURL;
        if (window.location.hash.includes("#")) {
          newWindowURL = `${window.location.protocol}//${window.location.host}/#/popoutChart?id=${prevData.length + 1}`;
        } else {
          newWindowURL = `${window.location.protocol}//${window.location.host}/popoutChart?id=${prevData.length + 1}`;
        }
        let PopoutData = {
          url: iframeUrl,
          data: data
        }
        store.commit('chart/setPopOutIframeData',[...store.state.chart.popOutIframeData,PopoutData])
        window.open(newWindowURL, "_blank");
      } else if (event.data.id === "startStream") {
        let streamSymbols = msgData.streamSyms ?? []; // List of symbol array need to subscribe
        store.commit("ws/setChartSubscribe", null);
        if (streamSymbols.length > 0) {
          let symbol = "";
          streamSymbols.forEach((el) => {
            symbol += el;
          });
          var tempData = {
            data: [],
            subscribe: symbol,
            where: "tradingChart",
          };
          store.commit("ws/setChartSubscribe", symbol);
          store.dispatch("ws/websocketSubscription", tempData);


          let tickerData = window.tickers
          let chartId = route.currentRoute.value.path == "/chart" ? document.getElementById('chartFrame') : document.getElementById('popoutFrame')
          if(tickerData && chartId && chartId.contentWindow && chartId.contentWindow){
           let message = {
            id: "stream",
            message: tickerData,
          };
          chartId.contentWindow?.postMessage(message, "*")
          }
        }
      } else if (
        event.data.id === "currentSymbol" &&
        route.currentRoute.value.path == "/chart" && event.data.data.currentSymbol
      ) {
          let activeSymol = event.data.data.currentSymbol
          let lastData = store.state.chart.tradeChartData
          lastData.symbol = activeSymol.symbol
          lastData.token = activeSymol.token
          lastData.exchange = activeSymol.exchange
          store.commit('chart/setTardeData', lastData)
        } else if (event.data.id === "apiError") {
        errorHandling.statusCodeCheck({error: {response: {status: event.data.data.error}}})
      } else if (event.data.id === "orderWindow") {
        let data = event.data.data;
        if (
          data.orderType == "NEW ORDER" ||
          data.orderType == "MODIFY ORDER" ||
          data.orderType == "CLOSE POSITION"
        ) {
          data.exch = data.ex = data.exc;

          data.price = !data.price
            ? ""
            : data.ex == "CDS" || data.ex == "BCD"
              ? parseFloat(data.price).toFixed(4)
              : parseFloat(data.price).toFixed(2);
          let isModify = false;
          let isBasket = false;
          let isBasketModify = false;
          let isRepeat = false;
          let page = "chart";
          var orderType = data.tradeType
            ? data.tradeType.toString().toLowerCase()
            : "";
          var btnName = data.tradeType;
          if (
            (data.orderType == "MODIFY ORDER" && data.orderParams) ||
            (data.positionParams && data.orderType == "CLOSE POSITION")
          ) {
            let whichOrderType = data.orderType;
            isModify = whichOrderType == "MODIFY ORDER" ? true : false;
            let tempPrice = data.price;
            data =
              whichOrderType == "MODIFY ORDER"
                ? data.orderParams
                : data.positionParams;
            if (whichOrderType == "MODIFY ORDER") {
              tempPrice ? (data.Prc = tempPrice) : "";
            }
            data.ex = data.Exchange;
            page = whichOrderType == "MODIFY ORDER" ? "orders" : "positions";

            if (whichOrderType == "CLOSE POSITION") {
              data.token = data.Token;
              orderType = Number(data.Netqty) > 0 ? "SELL" : "BUY";
              var btnName = orderType;
              var isExit = Number(data.Netqty) < 0 ? "sell" : "";
            } else {
              orderType = data.Trantype;
              // var priceType = common.getPrice(data.type);
              if (priceType == "SL") {
                await this.$store.commit("ow/setPrice", data.price);
              }
            }
          }

          await this.$store.commit("ow/setPriceTypeVal", priceType);
          var contractPayload = {
            exchange: data.ex,
            token: data.token,
          };
          store.dispatch("ow/getContractInfo", {
            contractPayload,
            data,
            btnName,
            isModify,
            page,
            isBasket,
            isBasketModify,
            isExit,
            isRepeat,
          });
        }
        if (data.orderType == "CANCEL ORDER") {
          store.commit("chart/setChartCancel", true);
          store.commit("chart/setCancelArray", data.orderParams);
        }
      } else if (event.data.id === "indicatorsStoring") {
        const msgData = event.data.data
        await this.$store.commit("chart/setChartIndicators", msgData);
      } else if (event.data.id === "themeUpdate"){
        let chartId = route.currentRoute.value.path == "/chart" ? document.getElementById('chartFrame') : document.getElementById('popoutFrame')
        if(chartId && chartId.contentWindow && chartId.contentWindow){
         let message = {
          id: "changeTheme",
          message:  {theme: store.state.pref.isTheme == '0' ? 'light' : 'Dark', color: store.state.pref.isTheme == '0' ? '#ffffff' : '#1E1E20'}
        };
        chartId.contentWindow?.postMessage(message, "*")
        }
      }
      if(msgData && msgData.hasOwnProperty('currentInterval')) {
        localStorage.setItem("currentChartInterval", msgData.currentInterval)
      }
    },
  },
  mounted() {
    window.addEventListener("message", this.chartListener);
    // window.addEventListener("currentInterval", )
  },
  unmounted() {
    window.removeEventListener("message", this.chartListener);
    store.commit("chart/setRefreshArray", "");
  }
};
